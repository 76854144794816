import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import Breadcrumb from "../../layout/breadcrumb";
import { Card, Col, Container, Row } from "reactstrap";
import { api } from "../../api/axios";

const View = (props) => {
  const { id } = useParams();
  const [collect, setCollect] = useState({});
  const [company, setCompany] = useState({});
  const [vehicle, setVehicle] = useState({});
  const [driver, setDriver] = useState({});
  const [date, setDate] = useState("");
  const [barrels, setBarrels] = useState([]);

  useEffect(() => {
    const func = async () => {
      const res = await api.get(`administrador/coleta/${id}`);
      const { coleta } = res.data;
      const { empresa, veiculo, motorista, data_coleta, tambores } = coleta;

      setCollect(coleta);
      setCompany(empresa);
      setVehicle(veiculo);
      setDriver(motorista);
      setDate(data_coleta);
      setBarrels(tambores);
    };
    if (id) func();
  }, [id]);

  return (
    <>
      <Breadcrumb parent="Coletas" title="Detalhes da Coleta" />
      <Container fluid={true}>
        <Card className="p-30" style={{ borderRadius: "0px 15px 15px 15px" }}>
          <Row>
            <Col>
              <p>
                <b style={{ color: "#5cad97" }}>Empresa: </b>{" "}
                {company.razao_social}
              </p>
              <p>
                <b style={{ color: "#5cad97" }}>Veículo: </b>
                {vehicle.placa}
              </p>
              <p>
                <b style={{ color: "#5cad97" }}>Motorista: </b>
                {driver.nome}
              </p>
            </Col>
            <Col>
              <p>
                <b style={{ color: "#5cad97" }}>Codigo: </b> {collect.codigo}
              </p>
              <p>
                <b style={{ color: "#5cad97" }}>Data: </b>
                {date.split("T")[0].split("-").reverse().join("/")}
              </p>
              <p>
                <b style={{ color: "#5cad97" }}>Avulsa: </b>{" "}
                {collect.is_avulsa ? "Sim" : "Não"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                <b style={{ color: "#5cad97" }}>Tambores: </b>
              </p>
              {barrels.map((el) => {
                return (
                  <p key={el.tambor_id}>{`- ${
                    el.nome_tambor
                  } (${el.peso.toFixed(2)} Kg)`}</p>
                );
              })}
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  );
};

export default View;
