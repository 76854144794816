import React, { useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import { Password, EmailAddress, RememberPassword } from "../../constant";
import { toast } from "react-toastify";
import { api } from "../../api/axios";

const Logins = (props) => {
  const jwt_token = localStorage.getItem("token");
  const [togglePassword, setTogglePassword] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [forgot, setForgot] = useState(false);
  const [wrongC, setWrongC] = useState(false);
  const history = useHistory();

  const checkError = () => {
    let err = {};

    err.email = !/\S+@\S+\.\S+/.test(email) || email.length < 5;
    err.password = password.length < 6;

    setErrors(err);

    return Object.values(err).filter((e) => {
      return e;
    }).length;
  };

  const HideShowPassword = (tPassword) => {
    setTogglePassword(!tPassword);
  };
  const handleForgot = async (e) => {
    e.preventDefault();
    checkError();
    if (!/\S+@\S+\.\S+/.test(email) || email.length < 5) return;

    try {
      await api.post(`publico/redefiniSenha`, {
        email: email,
      });
      history.go(0);
    } catch (err) {
      console.log(err.response.data.message);
      setErrors({ emailMiss: true });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (checkError() > 0) return;

    try {
      let res = await api.post(`api/login`, {
        login: email,
        pwd: password,
      });

      const resBody = await res.data;
      localStorage.setItem("token", resBody.access_token);
      localStorage.setItem("permissions", resBody.permissions);
      localStorage.setItem("Name", resBody.username);
      window.location.href = `${process.env.PUBLIC_URL}/dashboard/default`;
    } catch (err) {
      setWrongC(true);
      setPassword("");

      toast.error(err.response.data.message);
    }
  };

  return (
    <Container fluid={true} className="p-0">
      {jwt_token && <Redirect to={`${process.env.PUBLIC_URL}/`} />}
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card">
            <div>
              <div>
                <a className="logo" href="#javascript">
                  <img
                    className="img-fluid for-light"
                    style={{ maxWidth: "200px" }}
                    src={require("../../assets/images/logo/login.svg")}
                    alt=""
                  />
                  <img
                    className="img-fluid for-dark"
                    style={{ maxWidth: "200px" }}
                    src={require("../../assets/images/logo/logo_dark.svg")}
                    alt=""
                  />
                </a>
              </div>
              <div className="login-main">
                {forgot ? (
                  <Form
                    className="theme-form"
                    onSubmit={(e) => handleForgot(e)}
                  >
                    <h4>{"Redefinir a Senha"}</h4>
                    {/* <p>{"Preencha com seu email"}</p> */}
                    <FormGroup>
                      <Label className="col-form-label">{EmailAddress}</Label>
                      <Input
                        className={
                          errors["email"] || errors["emailMiss"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="seunome@gmail.com"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {errors["email"] && (
                        <span className="text-danger">Email inválido</span>
                      )}
                      {errors["emailMiss"] && (
                        <span className="text-danger">
                          Email não encontrado...
                        </span>
                      )}
                    </FormGroup>
                    <div className="form-group mb-0">
                      <Button color="primary" className="btn-block">
                        Recuperar
                      </Button>
                    </div>
                  </Form>
                ) : (
                  <Form
                    className="theme-form"
                    onSubmit={(e) => handleSubmit(e)}
                  >
                    <h4>{"Entrar"}</h4>
                    <p>{"Preencha com seu email e senha"}</p>
                    <FormGroup>
                      <Label className="col-form-label">{EmailAddress}</Label>
                      <Input
                        className={
                          errors["email"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="seunome@gmail.com"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {errors["email"] && (
                        <span className="text-danger">Email inválido</span>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label">{Password}</Label>
                      <Input
                        className={
                          errors["password"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type={togglePassword ? "text" : "password"}
                        name="login[password]"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required=""
                        placeholder="*********"
                      />
                      {errors["password"] && (
                        <span className="text-danger">
                          Senha inválida (Mínimo de 6 caracteres)
                        </span>
                      )}
                      <div
                        className="show-hide"
                        onClick={() => HideShowPassword(togglePassword)}
                      >
                        <span className={togglePassword ? "" : "show"}></span>
                      </div>
                    </FormGroup>
                    <div className="form-group mb-0">
                      <div className="checkbox ml-3">
                        <Input id="checkbox1" type="checkbox" />
                        <Label className="text-muted" for="checkbox1">
                          {RememberPassword}
                        </Label>
                      </div>
                      <Link
                        to="#"
                        className="link"
                        onClick={() => setForgot(true)}
                      >
                        Esqueceu a Senha?
                      </Link>
                      <Button color="primary" className="btn-block">
                        Entrar
                      </Button>
                    </div>
                    {wrongC && (
                      <p className="mt-4 mb-0 text-danger">
                        Email/Senha inválidos
                      </p>
                    )}
                  </Form>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Logins;
