import styled from "styled-components";
export const Container = styled.div`
  background-color: #D2F1E8;
  @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;600;900&display=swap');
  font-family: Nunito;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
`;
export const TopHeader = styled.header`
  background-color: #B0E5D6;
  display: flex;
  justify-content: center;
  padding: 1rem;

  a {
    background-color:#2AAD88;
    color: white;
    text-decoration: none;
    padding: .5rem 2rem;
    border-radius: 2rem;
    font-weight: bold;
  }
`;
export const Main = styled.main`
  padding: 5rem;
  padding-bottom: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 70%;
  img {
    margin-bottom: -1px;
    max-width: 500px;
    width: 80%;
  }
  p {
    font-size: 1.2rem;
    margin-top: 5rem;
    width: 80%;
    color: #296756;
  }
`;
export const Cards = styled.article`
  background-color:#2AAD88;
  color: #fff;
  padding: 5rem;
  font-weight: 600;
  section {
    margin: 0 auto;
    padding: .8rem;
    width: 60%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #72CAB1;
    h3 {
      text-transform: uppercase;
      font-weight: 900;
    }
    img {
      /* width: 10rem; */
    }
    div {
      margin: 2rem 0;
      margin-left: 2rem;
    }
  }
  section.sun {
    border-bottom: none;
  }
`;
export const OurServices = styled.section`
  text-align: center;
  h1 {
    color: #296756;
    margin: 3rem 0;
  }
  article {
    color: #464646;
    width: 70%;
    margin: 0 auto;
    margin-top: 1rem;
    background-color: #fff;
    padding: 2rem 3rem;
    border-radius: 1rem;
    text-align: left;
    h1 {
      margin: 1rem 0;
      margin-top: 2rem;
      color: #005290;
    }
    ul {
      margin-left: 2rem;
      list-style: outside;
    }
  }
`;

export const AmbientalLaw = styled.section`
  text-align: center;
  h1 {
    color: #296756;
    margin: 3rem 0;
  }
  article {
    color: #464646;
    width: 70%;
    margin: 0 auto;
    margin-top: 1rem;
    background-color: #fff;
    padding: 1rem 2rem;
    border-radius: 1rem;
    text-align: left;
    p {
      margin: 0;
    }
    b {
      color: #005290;
    }
  }
  img {
      width: 20%;
      margin: 10rem 5rem;
  }
`;
export const SocialMedia = styled.section`
  width: 40%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  img {
    width: 20%;
    max-width: 50px;

  }
`;

export const Footer = styled.footer`
  background-color: #348670;
  text-align: center;
  margin-top: 5rem;
  padding: 1rem;
  p {
    margin: 0;
    color: white;
    font-weight: 600;
  }
`;