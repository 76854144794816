import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "reactstrap";
import { CreateAccount } from "../../constant";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import { api } from "../../api/axios";

const Create = (props) => {
  const [ok, setOk] = useState(false);
  const [placa, setPlaca] = useState("");
  const [model, setModel] = useState("");
  const [color, setColor] = useState("");
  const [models, setModels] = useState([]);
  const [modalModel, setModalModel] = useState(false);
  const [modelName, setModelName] = useState("");
  const [refre, setRefre] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const func = async () => {
      const models = await api.get(`administrador/modelo/modelos`);
      setModels(
        models.data.modelos.map((el) => {
          return { value: el.id_modelo, label: el.modelo };
        })
      );
    };
    func();
  }, [refre]);

  const checkError = () => {
    let err = {};
    err["placa"] = placa.length < 5;
    err["model"] = model === "";
    err["color"] = color.length < 3;
    setErrors(err);
    return Object.values(err).filter((e) => {
      return e;
    }).length;
  };

  const handleCreateVehicle = async (e) => {
    e.preventDefault();
    if (checkError() > 0) return;
    setLoading(true);
    try {
      await api.post(`administrador/veiculos/cadastrar`, {
        placa,
        modelo: Number(model),
        cor: color,
      });
      setOk(true);
    } catch (err) {
      setLoading(false);
      if (err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Ops... Algo deu errado");
      }
    }
  };

  const handleCreateModel = async () => {
    try {
      await api.post(`administrador/modelo/cadastrar`, {
        modelo: modelName,
      });
      setRefre(!refre);
      setModalModel(false);
    } catch (err) {
      toast.error("Ops... Algo deu errado!");
    }
  };

  const toggleModalModel = (id) => {
    setModalModel(!modalModel);
  };

  return (
    <Fragment>
      {ok && <Redirect to={`${process.env.PUBLIC_URL}/veiculos/listar`} />}

      <Modal isOpen={modalModel} toggle={toggleModalModel}>
        <ModalHeader toggle={toggleModalModel}>
          Cadastrar Modelo do Veículo
        </ModalHeader>
        <ModalBody>
          <Label>Nome do Modelo</Label>
          <Input
            value={modelName}
            onChange={(e) => setModelName(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleCreateModel}>
            Ok
          </Button>
          <Button color="light" onClick={toggleModalModel}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      <Breadcrumb parent="Veículos" title="Cadastrar Veículo" />
      <Container fluid={true}>
        <Row>
          <Col xs="12">
            <div className="card">
              <Form
                className="card-body"
                onSubmit={(e) => handleCreateVehicle(e)}
              >
                <Button
                  style={{
                    position: "absolute",
                    right: "2.5rem",
                    top: "2.5rem",
                    marginBottom: "5rem",
                  }}
                  color="primary"
                  onClick={() => toggleModalModel()}
                >
                  Cadastrar novo modelo
                </Button>
                <FormGroup>
                  <Label
                    style={{ marginTop: "30px" }}
                    className="col-form-label"
                  >
                    Placa
                  </Label>
                  <Input
                    className={
                      errors["placa"]
                        ? "form-control border-danger"
                        : "form-control"
                    }
                    type="text"
                    required=""
                    placeholder="XXXXXXXX"
                    value={placa}
                    onChange={(e) => setPlaca(e.target.value)}
                  />
                  {errors["placa"] && (
                    <p className="text-danger">Placa inválida</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">Modelo</Label>
                  <Select
                    options={models}
                    onChange={(e) => setModel(e.value)}
                    noOptionsMessage={() => "Item não encontrado"}
                    placeholder={"Modelo"}
                    theme={(theme) => ({
                      ...theme,

                      colors: {
                        ...theme.colors,
                        primary: "#80bdff",
                      },
                    })}
                  />
                  {errors["model"] && (
                    <p className="text-danger">Modelo inválido</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">Cor</Label>
                  <Input
                    className={
                      errors["color"]
                        ? "form-control border-danger"
                        : "form-control"
                    }
                    type="text"
                    required=""
                    placeholder="Cor do Veículo"
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                  />
                  {errors["color"] && (
                    <p className="text-danger">Cor inválida</p>
                  )}
                </FormGroup>
                <div className="form-group mb-0">
                  <Button
                    color="primary"
                    className="btn-block"
                    type="submit"
                    disabled={loading}
                  >
                    {CreateAccount}
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Create;
