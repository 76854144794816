import React from "react";
import {
  Container,
  TopHeader,
  Main,
  Cards,
  OurServices,
  AmbientalLaw,
  SocialMedia,
  Footer,
} from "./styles";

import homeLogo from "../../assets/home/homeLogo.svg";
import homeHands from "../../assets/home/homeHands.svg";

import iconHand from "../../assets/home/iconHand.svg";
import iconThunder from "../../assets/home/iconThunder.svg";
import iconSun from "../../assets/home/iconSun.svg";
import iconRecyclable from "../../assets/home/iconRecyclable.svg";

import socialFace from '../../assets/home/socialFace.svg';
import socialInstagram from '../../assets/home/socialInstagram.svg';
import socialLinkedin from '../../assets/home/socialLinkedin.svg';
import socialTwitter from '../../assets/home/socialTwitter.svg';
import socialYoutube from '../../assets/home/socialYoutube.svg';

const Home = () => {
  return (
    <Container>
      <TopHeader>
        <a href="/dashboard/default">acessar o sistema</a>
      </TopHeader>
      <Main>
        <img src={homeLogo} alt="" />
        <p>
          A <b>Alpha Serviços</b> é uma empresa do Estado do Rio Grande do Norte
          que desenvolve atividades inerentes a gestão e gerenciamento de
          resíduos sólidos de serviços de saúde - RSSS (Classe 1 - perigosos),
          oferecendo soluções ambientalmente adequadas com vistas a{" "}
          <b> sustentabilidade</b>.
        </p>
        <img src={homeHands} alt="" />
      </Main>
      <Cards>
        <section>
          <img src={iconHand} alt="" />
          <div>
            <h3>Nossa missão</h3>
            <p>
              Construir parceria com nossos clientes fundamentada na
              sustentabilidade, proporcionando o melhor em questão e
              gerenciamento de resíduos sólidos.
            </p>
          </div>
        </section>
        <section>
          <img src={iconThunder} alt="" />
          <div>
            <h3>NOSSOS VALORES</h3>
            <p>
              Responsabilidade, respeito e compromisso com os profissionais que
              atuam junto a Empresa, com nossos clientes e demais membros da
              sociedade.
            </p>
          </div>
        </section>
        <section className="sun">
          <img src={iconSun} alt="" />
          <div>
            <h3>NOSSA VISÃO</h3>
            <p>
              Construir parceria com nossos clientes fundamentada na
              sustentabilidade, proporcionando o melhor em questão e
              gerenciamento de resíduos sólidos.
            </p>
          </div>
        </section>
      </Cards>

      <OurServices>
        <h1> Conheça nossos serviços! </h1>
        <article>
          <h1>Gerenciamento de resíduos sólidos</h1>
          <p>
            O gerenciamento de resíduos sólidos e orientado em procedimentos de
            gestão, planejados e exercidos tendo como fundamentos critérios
            científicos e técnicos, além de normas legais, apresentando como
            premissa a minimização da produção de resíduos, buscando
            proporcionar aos resíduos gerados, um tratamento eficiente e seguro,
            visando sempre a garantia da qualidade da vida dos seres humanos e
            do meio ambiente como um todo.
          </p>
          <h2>Etapas do gerenciamento</h2>
          <p>
            O gerenciamento deve abranger todas as etapas de planejamento,
            incluindo, os recursos humanos capacitados, recursos materiais,
            recursos físicos e recursos financeiros. Sempre buscando as melhores
            soluções em controle, gestão e tecnologia ambiental.
          </p>
          <ul>
            <li> Operacionalização do gerenciamento de resíduos sólidos;</li>
            <li> Geração controlado;</li>
            <li> Acondicionamento adequado no pós-geração;</li>
            <li> Coleta e transporte interno</li>
            <li> Acondicionamento externo ambientalmente seguro</li>
            <li> Coleta e transporte externo ambientalmente seguro</li>
            <li> Tratamento fundamentado no desenvolvimento sustentável</li>
          </ul>
        </article>

        <article>
          <h1>Coleta e transporte externo de resíduos sólidos</h1>
          <p>
            A Alpha serviços e especializada nas etapas de coleta e transporte
            de resíduos sólidos, principalmente classe 1 (perigosos – de serviço
            de saúde previstos na RDC 306/04 ANVISA). A Alpha dispõe de veículos
            certificados pelo INMETRO, e profissionais (condutores) habilitados
            para Movimentação e Operação de Produtos e Resíduos Perigosos. Os
            profissionais que executam as atividades de coleta e transporte de
            RSSS dispõem de conhecimento técnico e operacional, assim como de
            recursos materiais para executarem as tarefas de forma segura
            (EPI’S).
          </p>
          <p>
            No gerencialmente de resíduos sólidos. Os serviços de coleta e o
            transporte, são tão importantes quanto as etapas anteriores e
            posteriores do gerenciamento. Portanto, cabe ao gerador do resíduo,
            estabelecer uma relação formal com uma empresa licenciada e
            especializada nos serviços de coleta e transporte de resíduos,
            através da qual, os resíduos gerados possam chegar ate a etapa de
            tratamento (destinação ambientalmente adequada).
          </p>
        </article>

        <article>
          <h1>Tratamento de resíduos sólidos de serviço de saúde</h1>
          <p>
            Consiste na aplicação de método, técnica ou processo que de acordo
            com as características físicas, químicas e biológicas, assim como ao
            risco inerente do resíduo, esse possa ser reciclado; aterrado em
            aterros sanitários e industriais; incinerados; coprocessados e/ou
            outros métodos / técnicas / processos ambientalmente aceitos pela
            legislação brasileira.
          </p>
          <p>
            A Alpha serviços dispõe de parceria formal com empresa especializada
            e licenciada para o tratamento térmico de resíduos sólidos Classe 1
            (perigosos de serviços de saúde) A Lavieri e a parceira no
            tratamento térmico, dispondo de dois incineradores com capacidade de
            100 a 50kg/h. A planta de incineração esta situada em Campina
            Grande/PB.
          </p>
        </article>
      </OurServices>

      <AmbientalLaw>
        {/* <h1>Licenciamento ambiental e registros</h1>
        <article>
          <p>
            <b>IDEMA</b> - Nº. 2017-105532/TEC/LO-003 - Val. 22.03.19;
          </p>
        </article>

        <article>
          <p>
            <b>IBAMA</b> - Registro nº. 6731786;
          </p>
        </article>

        <article>
          <p>
            <b>Conselho Regional de Quimica</b> - CRQ XV nº. 15.38.00626;
          </p>
        </article> */}

        <img src={iconRecyclable} alt="" />
      </AmbientalLaw>

      <SocialMedia>
        <img src={socialFace} alt="" />
        <img src={socialTwitter} alt="" />
        <img src={socialLinkedin} alt="" />
        <img src={socialInstagram} alt="" />
        <img src={socialYoutube} alt="" />
      </SocialMedia>
      <Footer>
        <p>Alpha serviços - Todos os direitos reservados</p>
      </Footer>
    </Container>
  );
};
export default Home;
