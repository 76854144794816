import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Card,
  Col,
  ModalBody,
  Input,
  InputGroupText,
  InputGroup,
  UncontrolledTooltip,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { api } from "../../api/axios";
import ReactLoading from "react-loading";

import { Edit, Eye, Search, Trash2 } from "react-feather";

const List = (props) => {
  const [modal, setModal] = useState(false);
  const [barrels, setBarrels] = useState([]);
  const [selected, setSelected] = useState("");
  const [refre, setRefre] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const func = async () => {
      const res = await api.get(`administrador/tambores?page=0&enable=true`);
      setBarrels(res.data.tambores);
      setLoading(false);
    };
    func();
  }, [refre]);

  const toggleModal = (id, nome) => {
    setModal(!modal);
    if (id) {
      setSelected({ id: id, nome: nome });
    }
  };

  const handleSubmit = async () => {
    try {
      setModal(false);
      await api.get(`administrador/tambores/${selected}/desabilitar`);
      setRefre(!refre);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  return (
    <Fragment>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Confirmar</ModalHeader>
        <ModalBody>
          <p>
            O Tambor <b style={{ color: "#5cad97" }}>{selected.nome}</b> será
            apagado permanentemente do sistema. Deseja prosseguir?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleSubmit}>
            Ok
          </Button>
          <Button color="light" onClick={toggleModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      <Breadcrumb parent="Tambores" title="Lista de Tambores" />
      <Container fluid={true}>
        <Row style={{ marginBottom: "20px" }}>
          <Col xs="6">
            <InputGroup>
              <InputGroupText
                className="border border-right-0"
                style={{ background: "transparent" }}
              >
                <Search size="1rem" />
              </InputGroupText>
              <Input disabled />
            </InputGroup>
          </Col>
          <Col xs="6">
            <Link
              to={`${process.env.PUBLIC_URL}/tambores/cadastrar`}
              className="btn btn-primary float-right"
            >
              Cadastrar Tambor
            </Link>
          </Col>
        </Row>
        {loading && (
          <div className="d-flex justify-content-center">
            <ReactLoading type="balls" color="#5cad97" />
          </div>
        )}
        {barrels.map((el, i) => {
          return (
            <Card className="p-30" key={el.id_tambor}>
              <Row>
                <Col xs="10" md="3" className="mb-3">
                  <b style={{ color: "#5cad97" }}>NOME: </b>
                  {el.nome}
                </Col>
                <Col xs="10" md="3" className="mb-3">
                  <b style={{ color: "#5cad97" }}>CAPACIDADE: </b>
                  {el.capacidade}
                </Col>
                <Col xs="10" md="3" className="mb-3">
                  <b style={{ color: "#5cad97" }}>LITROS: </b>
                  {el.litros}
                </Col>
              </Row>
              <Row>
                <Col xs="10" className="mb-3">
                  <b style={{ color: "#5cad97" }}>
                    DESCRIÇÃO: <br />
                  </b>
                  {el.descricao}
                </Col>
              </Row>

              <div
                style={{
                  position: "absolute",
                  right: "1.5rem",
                  bottom: "1.5rem",
                }}
              >
                <Link
                  to={`${process.env.PUBLIC_URL}/tambores/visualizar/${el.id_tambor}`}
                >
                  <Eye id={"detalhes" + i} />
                  <UncontrolledTooltip placement="top" target={"detalhes" + i}>
                    Detalhes
                  </UncontrolledTooltip>
                </Link>

                <Link
                  style={{ marginLeft: "1rem" }}
                  to={`${process.env.PUBLIC_URL}/tambores/atualizar/${el.id_tambor}`}
                >
                  <Edit id={"editar" + i} />
                  <UncontrolledTooltip placement="top" target={"editar" + i}>
                    Editar
                  </UncontrolledTooltip>
                </Link>
                <Link
                  style={{ marginLeft: "1rem" }}
                  to={"#"}
                  onClick={() => toggleModal(el.id_tambor, el.descricao)}
                >
                  <Trash2 id={"excluir" + i} />
                  <UncontrolledTooltip placement="top" target={"excluir" + i}>
                    Excluir
                  </UncontrolledTooltip>
                </Link>
              </div>
            </Card>
          );
        })}
      </Container>
    </Fragment>
  );
};

export default List;
