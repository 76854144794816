import React, { Fragment, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { CreateAccount } from "../../constant";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { api } from "../../api/axios";
import { cpfMask, homePhoneMask, phoneMask } from "../../utils/masks";

const Create = (props) => {
  const [ok, setOk] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCPF] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const checkError = () => {
    let err = {};
    err.name = name.length < 5;
    err.email = !/\S+@\S+\.\S+/.test(email) || email.length < 5;
    err.phone = phone.length < 1;
    err.cpf = cpf.length < 14;
    setErrors(err);
    return Object.values(err).filter((e) => {
      return e;
    }).length;
  };

  const handleCreateAdmin = async (e) => {
    e.preventDefault();
    if (checkError() > 0) return;
    setLoading(true);
    try {
      await api.post(`administrador/cadastrar`, {
        nome: name,
        email,
        cpf,
        telefone: phone.replace(".", ""),
      });
      setOk(true);
    } catch (err) {
      setLoading(false);
      if (err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Ops... Algo deu errado");
      }
    }
  };

  return (
    <Fragment>
      {ok && (
        <Redirect to={`${process.env.PUBLIC_URL}/administradores/listar`} />
      )}
      <Breadcrumb parent="Administradores" title="Cadastrar Administrador" />
      <Container fluid={true}>
        <Row>
          <Col xs="12">
            <div className="card">
              <Form
                className="card-body"
                onSubmit={(e) => handleCreateAdmin(e)}
              >
                <div className="form-row">
                  <Col xs="6">
                    <FormGroup>
                      <Label className="col-form-label">Nome</Label>
                      <Input
                        className={
                          errors["name"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="Nome"
                        value={name}
                        onChange={(e) =>
                          setName(
                            e.target.value.charAt(0).toUpperCase() +
                              e.target.value.slice(1)
                          )
                        }
                      />
                      {errors["name"] && (
                        <p className="text-danger">Nome inválido</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <Label className="col-form-label">Email</Label>
                      <Input
                        className={
                          errors["email"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="nome@email.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {errors["email"] && (
                        <p className="text-danger">Email inválido</p>
                      )}
                    </FormGroup>
                  </Col>
                </div>
                <div className="form-row">
                  <Col xs="6">
                    <FormGroup>
                      <Label className="col-form-label">CPF</Label>
                      <Input
                        className={
                          errors["cpf"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="000.000.000-00"
                        value={cpf}
                        onChange={(e) => setCPF(cpfMask(e.target.value))}
                      />
                      {errors["cpf"] && (
                        <p className="text-danger">CPF inválido</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <Label className="col-form-label">Telefone</Label>
                      <Input
                        className={
                          errors["phone"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="(00) 0.0000-0000"
                        value={phone}
                        onChange={(e) => {
                          e.target.value[5] === "9"
                            ? setPhone(phoneMask(e.target.value))
                            : setPhone(homePhoneMask(e.target.value));
                        }}
                      />
                      {errors["phone"] && (
                        <p className="text-danger">Valor inválido</p>
                      )}
                    </FormGroup>
                  </Col>
                </div>

                <div className="form-group mb-0">
                  <Button
                    color="primary"
                    className="btn-block"
                    type="submit"
                    disabled={loading}
                  >
                    {CreateAccount}
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Create;
