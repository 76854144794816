import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  Col,
  Card,
  InputGroup,
  InputGroupText,
  Input,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { api } from "../../api/axios";
import { toast } from "react-toastify";
import { Next, Previous } from "../../constant";
import { Search, Download, RefreshCw, Trash2 } from "react-feather";
import {
  leftButtonText,
  middleButtonText,
  rightButtonText,
  leftButtonClick,
  middleButtonClick,
  rightButtonClick,
  middleButtonActive,
  handlePrevPageChange,
} from "../../utils/pagination";
import ReactLoading from "react-loading";

const List = (props) => {
  const [charges, setCharges] = useState([]);
  const [page, setPage] = useState(1);
  const [pgCount, setPgCount] = useState(0);
  const [search, setSearch] = useState("");
  const [refre, setRefre] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState({});

  const toggleModal = (id, nome) => {
    setModal(!modal);
    if (id) {
      setSelected({ id: id, nome: nome });
    }
  };

  const handleSubmit = async () => {
    try {
      setModal(false);
      await api.delete(`administrador/cobranca/${selected.id}/cancelar`);
      setRefre(!refre);
    } catch (err) {
      console.log(err);
      if (err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error("opps!");
      }
    }
  };

  useEffect(() => {
    const func = async () => {
      setLoading(true);
      const charges = await api.get(
        `administrador/cobranca?page=${page}&enable=true`
      );
      setCharges(charges.data.cobrancas);
      setPgCount(charges.data.pagination.total_pages);
      setLoading(false);
    };
    func();
  }, [page, refre]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      setLoading(true);
      const res = await api.get(
        `administrador/cobranca?page=0&enable=true&query=${search}`
      );
      setCharges(res.data.cobrancas);
      setLoading(false);
    }, 200);
    return () => clearTimeout(timer);
  }, [search]);

  const calcDue = (date) => {
    let today = new Date();
    let due = new Date(date);
    due.setDate(due.getDate() + 10);

    return due <= today;
  };

  const refreshBoleto = async (id, value) => {
    try {
      let due = new Date();
      due.setDate(due.getDate() + 10);

      await api.put(`administrador/cobranca/${id}/atualizar`, {
        data_vencimento: due.toISOString().split("T")[0],
        valor: value,
      });

      toast.success("Boleto reemitido com sucesso!");
      setRefre(!refre);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  return (
    <Fragment>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Confirmar</ModalHeader>
        <ModalBody>
          <p>
            Uma cobrança da empresa{" "}
            <b style={{ color: "#5cad97" }}>{selected.nome}</b> será apagada
            permanentemente do sistema. Deseja prosseguir?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleSubmit}>
            Ok
          </Button>
          <Button color="light" onClick={toggleModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Breadcrumb parent="Cobranças" title="Lista de Cobranças" />
      <Container fluid={true}>
        <Row style={{ marginBottom: "20px" }}>
          <Col xs="6">
            <InputGroup>
              <InputGroupText
                className="border border-right-0"
                style={{ background: "transparent" }}
              >
                <Search size="1rem" />
              </InputGroupText>
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputGroup>
          </Col>
          <Col xs="6">
            <Link
              to={`${process.env.PUBLIC_URL}/cobrancas/cadastrar`}
              className="btn btn-primary float-right"
            >
              Nova Cobrança
            </Link>
          </Col>
        </Row>
        {loading ? (
          <div className="d-flex justify-content-center">
            <ReactLoading type="balls" color="#5cad97" />
          </div>
        ) : (
          charges.map((el, i) => {
            return (
              <Card className="p-30" key={el.id_cobranca}>
                <Row>
                  <Col xs="10" md="5" className="mb-3">
                    <b style={{ color: "#5cad97" }}>NOME: </b>
                    {el.razao_social}
                  </Col>
                  <Col xs="10" md="5" className="mb-3">
                    <b style={{ color: "#5cad97" }}>VALOR: </b>
                    {el.valor_formatado}
                  </Col>
                </Row>
                <Row>
                  <Col xs="10" md="5" className="mb-3">
                    <b style={{ color: "#5cad97" }}>DATA: </b>
                    {el.mes_ref.split("-").reverse().join("/")}
                  </Col>
                  <Col xs="10" md="5" className="mb-3">
                    <b style={{ color: "#5cad97" }}>GERADO EM: </b>
                    {el.data_gerado
                      .split("T")[0]
                      .split("-")
                      .reverse()
                      .join("/")}
                  </Col>
                </Row>
                <Row>
                  <Col xs="10" md="5" className="mb-3">
                    <b style={{ color: "#5cad97" }}>STATUS: </b>
                    {el.status_formatado}
                  </Col>
                  <Col xs="10" md="5" className="mb-3">
                    <b style={{ color: "#5cad97" }}>AVULSA: </b>
                    {el.is_avulso ? "Sim" : "Não"}
                  </Col>
                </Row>
                {el.status !== 3 && (
                  <div
                    style={{
                      position: "absolute",
                      right: "1.5rem",
                      bottom: "1.5rem",
                    }}
                  >
                    {calcDue(el.data_gerado) && (
                      <Link
                        style={{ marginLeft: "1rem" }}
                        to="#"
                        onClick={() => refreshBoleto(el.id_cobranca, el.valor)}
                      >
                        <RefreshCw id={"atualizar" + i} />
                        <UncontrolledTooltip
                          placement="top"
                          target={"atualizar" + i}
                        >
                          Reemitir Boleto
                        </UncontrolledTooltip>
                      </Link>
                    )}

                    {!el.url ? (
                      <Link
                        style={{ marginLeft: "1rem" }}
                        to="#"
                        onClick={() => refreshBoleto(el.id_cobranca, el.valor)}
                      >
                        <RefreshCw id={"atualizar" + i} />
                        <UncontrolledTooltip
                          placement="top"
                          target={"atualizar" + i}
                        >
                          Reemitir Boleto
                        </UncontrolledTooltip>
                      </Link>
                    ) : (
                      <Link
                        style={{ marginLeft: "1rem" }}
                        to={"#"}
                        onClick={() => el.url && window.open(el.url)}
                      >
                        <Download id={"boleto" + i} />
                        <UncontrolledTooltip
                          placement="top"
                          target={"boleto" + i}
                        >
                          Baixar Boleto
                        </UncontrolledTooltip>
                      </Link>
                    )}
                    <Link
                      style={{ marginLeft: "1rem" }}
                      to={"#"}
                      onClick={() =>
                        toggleModal(el.id_cobranca, el.razao_social)
                      }
                    >
                      <Trash2 id={"cobra" + i} />
                      <UncontrolledTooltip
                        placement="top"
                        target={"cobra" + i}
                      >
                        Cancelar cobrança
                      </UncontrolledTooltip>
                    </Link>
                  </div>
                )}
              </Card>
            );
          })
        )}

        {search.length <= 0 && !loading && (
          <Card>
            <div className="mx-auto">
              {pgCount !== 1 && (
                <Pagination
                  className="m-30"
                  aria-label="Page navigation example"
                >
                  <ul className="pagination pagination-primary">
                    <PaginationItem disabled={page === 1}>
                      <PaginationLink
                        onClick={() => setPage(handlePrevPageChange(page))}
                      >
                        {Previous}
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem active={page === 1}>
                      <PaginationLink
                        onClick={() => setPage(leftButtonClick(page, pgCount))}
                      >
                        {leftButtonText(page, pgCount)}
                      </PaginationLink>
                    </PaginationItem>

                    <PaginationItem active={middleButtonActive(page, pgCount)}>
                      <PaginationLink
                        onClick={() =>
                          setPage(middleButtonClick(page, pgCount))
                        }
                      >
                        {middleButtonText(page, pgCount)}
                      </PaginationLink>
                    </PaginationItem>
                    {pgCount !== 2 && (
                      <PaginationItem active={page === pgCount}>
                        <PaginationLink
                          onClick={() =>
                            setPage(rightButtonClick(page, pgCount))
                          }
                        >
                          {rightButtonText(page, pgCount)}
                        </PaginationLink>
                      </PaginationItem>
                    )}
                    <PaginationItem disabled={page === pgCount}>
                      <PaginationLink onClick={() => setPage(page + 1)}>
                        {Next}
                      </PaginationLink>
                    </PaginationItem>
                  </ul>
                </Pagination>
              )}
            </div>
          </Card>
        )}
      </Container>
    </Fragment>
  );
};

export default List;
