import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import Breadcrumb from "../../layout/breadcrumb";
import { Card, Col, Container, Row } from "reactstrap";
import { api } from "../../api/axios";

const View = (props) => {
  const { id } = useParams();
  const [admin, setAdmin] = useState({});

  useEffect(() => {
    const func = async () => {
      const res = await api.get(`administrador/${id}`);
      setAdmin(res.data.usuario);
    };
    if (id) func();
  }, [id]);

  return (
    <>
      <Breadcrumb parent="Administradores" title="Detalhes do Administrador" />
      <Container fluid={true}>
        <Card className="p-30" style={{ borderRadius: "0px 15px 15px 15px" }}>
          <Row>
            <Col>
              <p>
                <b style={{ color: "#5cad97" }}>Nome: </b> {admin.nome}
              </p>
              <p>
                <b style={{ color: "#5cad97" }}>Email: </b> {admin.email}
              </p>
            </Col>
            <Col>
              <p>
                <b style={{ color: "#5cad97" }}>Cpf: </b> {admin.cpf}
              </p>
              <p>
                <b style={{ color: "#5cad97" }}>Telefone: </b> {admin.telefone}
              </p>
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  );
};

export default View;
