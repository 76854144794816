import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import { cepMask, dateMask, numberMask } from "../../utils/masks";
import { Redirect, useParams } from "react-router";
import { api } from "../../api/axios";

const Create = (props) => {
  const [ok, setOk] = useState(false);
  // Contract
  const [chargeType, setChargeType] = useState("");
  const [excedentValue, setExcedentValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dueDay, setDueDay] = useState("");
  const [collectFreq, setCollectFreq] = useState("");
  const [minQuant, setMinQuant] = useState("");
  const [minMValue, setMinMValue] = useState("");
  const [minAValue, setMinAValue] = useState("");
  const [chargeValue, setChargeValue] = useState("");
  const [barrelFromAPI, setBarrelFromAPI] = useState([]);
  const [selBarrel, setSelBarrel] = useState("");
  const [barrels, setBarrels] = useState([]);
  // Address
  const [address, setAddress] = useState("");
  const [num, setNum] = useState("");
  const [neighbor, setNeighbor] = useState("");
  const [city, setCity] = useState("");
  const [stateAd, setStateAd] = useState("");
  const [cep, setCep] = useState("");
  const [errors, setErrors] = useState({});
  const [errorsBarrel, setErrorsBarrel] = useState({});
  const [week, setWeek] = useState([]);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  useEffect(() => {
    const func = async () => {
      const res = await api.get(`administrador/tambores/select_tambores`);
      setBarrelFromAPI(res.data.tambores);
    };
    func();
  }, []);

  useEffect(() => {
    const func = async () => {
      const res = await api.get(`administrador/contrato/${id}`);
      const json = await res.data;
      setChargeType(json.tipoCobranca === 0 ? "Kilo" : "Tambor");
      setExcedentValue(
        numberMask(
          json.valorExcedenteFormatado.replace("R$ ", "").replace(",", "")
        )
      );
      setStartDate(json.dataInicio);
      setEndDate(json.dataFim);
      setDueDay(json.diaVencimento);
      setCollectFreq(json.frequenciaColeta);
      setMinQuant(json.qtMinimaColeta);
      setMinMValue(numberMask(json.valorMinimoMensal.toFixed(2)));
      setMinAValue(numberMask(json.valorMinimoAnual.toFixed(2)));
      setAddress(json.enderecoColeta.logradouro);
      setNum(json.enderecoColeta.numero);
      setNeighbor(json.enderecoColeta.bairro);
      setCity(json.enderecoColeta.cidade);
      setStateAd(json.enderecoColeta.estado);
      setCep(json.enderecoColeta.cep);
      setBarrels(json.tambores);
      setWeek(json.diaSemana);
    };
    if (id) func();
  }, [id]);

  const checkError = () => {
    let err = {};

    err["chargeType"] = chargeType === "";
    err["excedentValue"] = excedentValue === "" || Number(excedentValue) < 0;
    err["collectFreq"] = collectFreq === "" || Number(collectFreq) < 0;
    err["startDate"] = startDate.length < 10;
    err["endDate"] = endDate.length < 10;
    err["dueDay"] = dueDay === "" || Number(dueDay) < 1;
    err["minQuant"] = minQuant === "" || Number(minQuant) < 0;
    err["minMValue"] = minMValue === "" || Number(minMValue) < 0;
    err["minAValue"] = minAValue === "" || Number(minAValue) < 0;
    err["address"] = address.length < 5;
    err["neighbor"] = neighbor.length < 5;
    err["city"] = city.length < 5;
    err["cep"] = !/^\d{5}-\d{3}$/.test(cep);
    err["stateAd"] = stateAd.length < 1;
    err["num"] = num.length < 1;

    setErrors(err);
    return Object.values(err).filter((e) => {
      return e;
    }).length;
  };
  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (checkError() > 0) return;
    setLoading(true);

    const addr = {
      cep: cep,
      bairro: neighbor,
      cidade: city,
      estado: stateAd,
      logradouro: address,
      numero: num,
    };
    try {
      await api.patch(`administrador/contrato/${id}/atualizar`, {
        tipoContrato: 1,
        contrato_id: id,
        tipoCobranca: chargeType === "Kilo" ? 0 : 1,
        valorExcedente: Number(
          excedentValue.replace(".", "").replace(",", ".")
        ),
        enderecoColeta: addr,
        enderecoContrato: addr,
        dataInicio: startDate,
        dataFim: endDate,
        diaVencimento: Number(dueDay),
        qtMinimaColeta: Number(minQuant),
        valorMinimoMensal: Number(minMValue.replace(".", "").replace(",", ".")),
        valorMinimoAnual: Number(minAValue.replace(".", "").replace(",", ".")),
        frequenciaColeta: Number(collectFreq),
        tambores: barrels.map((e) => {
          delete e["nome"];
          delete e["descricao"];
          return e;
        }),
        diaSemana: week,
      });
      setOk(true);
    } catch (err) {
      setLoading(false);
      if (err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Ops... Algo deu errado");
      }
    }
  };

  const handleAddBarrel = () => {
    let err = {};
    err["chargeValue"] = chargeValue.length < 2;
    err["selBarrel"] = selBarrel.length < 1;

    setErrorsBarrel(err);
    if (err.chargeValue || err.selBarrel) return;

    setBarrels([
      ...barrels,
      {
        tambor_id: selBarrel,
        nome: barrelFromAPI.find((el) => el.id_tambor === selBarrel).nome,
        valorCobranca: parseFloat(
          chargeValue.replace(".", "").replace(",", ".")
        ),
      },
    ]);
  };
  const handleDelBarrel = (i) => {
    let bar = [...barrels];
    bar.splice(i, 1);
    setBarrels(bar);
  };

  return (
    <Fragment>
      {ok && <Redirect to={`${process.env.PUBLIC_URL}/contratos/listar`} />}
      <Breadcrumb parent="Contratos" title="Atualizar Contrato" />
      <Container fluid={true}>
        <Row>
          <Col xs="12">
            <div className="card">
              <Form className="card-body" onSubmit={(e) => onFormSubmit(e)}>
                <FormGroup>
                  <div className="form-row">
                    <Col xs="4">
                      <Label className="col-form-label pt-0">
                        Tipo da Cobrança
                      </Label>
                      <Input
                        className={
                          errors["chargeType"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="select"
                        required=""
                        placeholder=""
                        value={chargeType}
                        onChange={(e) => setChargeType(e.target.value)}
                      >
                        <option disabled></option>
                        <option>Kilo</option>
                        <option>Tambor</option>
                      </Input>
                      {errors["chargeType"] && (
                        <p className="text-danger">Tipo de Cobrança inválido</p>
                      )}
                    </Col>
                    <Col xs="4">
                      <Label className="col-form-label pt-0">
                        Valor Excedente
                      </Label>
                      <InputGroup>
                        <InputGroupText
                          className={
                            errors["excedentValue"]
                              ? "border-danger border-right-0"
                              : ""
                          }
                          style={{ borderRadius: "2px 0 0 2px" }}
                        >
                          R$
                        </InputGroupText>
                        <Input
                          className={
                            errors["excedentValue"]
                              ? "form-control border-danger border-left-0"
                              : "form-control"
                          }
                          type="text"
                          required=""
                          placeholder="00,00"
                          value={excedentValue}
                          onChange={(e) =>
                            setExcedentValue(numberMask(e.target.value))
                          }
                        />
                      </InputGroup>
                      {errors["excedentValue"] && (
                        <p className="text-danger">Valor excedente inválido</p>
                      )}
                    </Col>
                    <Col xs="4">
                      <Label className="col-form-label pt-0">
                        Frequência da coleta
                      </Label>
                      <Input
                        className={
                          errors["collectFreq"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="0"
                        value={collectFreq}
                        onChange={(e) => setCollectFreq(e.target.value)}
                      />
                      {errors["collectFreq"] && (
                        <p className="text-danger">
                          Frequência da coleta Inválida
                        </p>
                      )}
                    </Col>
                  </div>
                </FormGroup>

                <FormGroup>
                  <div className="form-row">
                    <Col xs="4">
                      <Label className="col-form-label pt-0">
                        Data de Início
                      </Label>
                      <Input
                        className={
                          errors["startDate"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="00/00/0000"
                        value={startDate}
                        onChange={(e) => setStartDate(dateMask(e.target.value))}
                      />
                      {errors["startDate"] && (
                        <p className="text-danger">Data inválida</p>
                      )}
                    </Col>
                    <Col xs="4">
                      <Label className="col-form-label pt-0">
                        Data fim da vigência
                      </Label>
                      <Input
                        className={
                          errors["endDate"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="00/00/0000"
                        value={endDate}
                        onChange={(e) => setEndDate(dateMask(e.target.value))}
                      />
                      {errors["endDate"] && (
                        <p className="text-danger">Data inválida</p>
                      )}
                    </Col>
                    <Col xs="4">
                      <Label className="col-form-label pt-0">
                        Dia do Vencimento
                      </Label>
                      <Input
                        className={
                          errors["dueDay"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="00"
                        value={dueDay}
                        onChange={(e) => setDueDay(e.target.value)}
                      />
                      {errors["dueDay"] && (
                        <p className="text-danger">Dia inválido</p>
                      )}
                    </Col>
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="form-row">
                    <Col xs="12">
                      <Label>SEMANA</Label>
                      <Select
                        value={week.map((e) => {
                          return { value: Number(e.dia), label: e.nome };
                        })}
                        onChange={(e) => {
                          setWeek(
                            e.map((el) => {
                              return {
                                nome: el.label,
                                dia: el.value,
                                value: el.value,
                              };
                            })
                          );
                        }}
                        isMulti
                        options={[
                          { value: 1, label: "Domingo" },
                          { value: 2, label: "Segunda-Feira" },
                          { value: 3, label: "Terça-Feira" },
                          { value: 4, label: "Quarta-Feira" },
                          { value: 5, label: "Quinta-Feira" },
                          { value: 6, label: "Sexta-Feira" },
                          { value: 7, label: "Sabado" },
                        ]}
                      />
                    </Col>
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="form-row">
                    <Col xs="4">
                      <Label className="col-form-label pt-0">
                        Quantidade Mínima
                      </Label>
                      <Input
                        className={
                          errors["minQuant"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="0"
                        value={minQuant}
                        onChange={(e) => setMinQuant(e.target.value)}
                      />
                      {errors["minQuant"] && (
                        <p className="text-danger">Valor inválido</p>
                      )}
                    </Col>
                    <Col xs="4">
                      <Label className="col-form-label pt-0">
                        Valor Mínimo Mensal
                      </Label>
                      <InputGroup>
                        <InputGroupText
                          className={
                            errors["minMValue"]
                              ? "border-danger border-right-0"
                              : ""
                          }
                          style={{ borderRadius: "2px 0 0 2px" }}
                        >
                          R$
                        </InputGroupText>
                        <Input
                          className={
                            errors["minMValue"]
                              ? "form-control border-danger border-left-0"
                              : "form-control"
                          }
                          type="text"
                          required=""
                          placeholder="00,00"
                          value={minMValue}
                          onChange={(e) =>
                            setMinMValue(numberMask(e.target.value))
                          }
                        />
                      </InputGroup>
                      {errors["minMValue"] && (
                        <p className="text-danger">Valor inválido</p>
                      )}
                    </Col>
                    <Col xs="4">
                      <Label className="col-form-label pt-0">
                        Valor Mínimo Anual
                      </Label>
                      <InputGroup>
                        <InputGroupText
                          className={
                            errors["minAValue"]
                              ? "border-danger border-right-0"
                              : ""
                          }
                          style={{ borderRadius: "2px 0 0 2px" }}
                        >
                          R$
                        </InputGroupText>
                        <Input
                          className={
                            errors["minAValue"]
                              ? "form-control border-danger border-left-0"
                              : "form-control"
                          }
                          type="text"
                          required=""
                          placeholder="00,00"
                          value={minAValue}
                          onChange={(e) =>
                            setMinAValue(numberMask(e.target.value))
                          }
                        />
                      </InputGroup>
                      {errors["minAValue"] && (
                        <p className="text-danger">
                          Quantidade mínima inválida
                        </p>
                      )}
                    </Col>
                  </div>
                </FormGroup>
                <h2>Endereço da Coleta</h2>
                <FormGroup>
                  <div className="form-row">
                    <Col xs="7">
                      <Label className="col-form-label pt-0">Logradouro</Label>
                      <Input
                        className={
                          errors["address"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="Nome da rua"
                        value={address}
                        onChange={(e) =>
                          setAddress(
                            e.target.value.charAt(0).toUpperCase() +
                              e.target.value.slice(1)
                          )
                        }
                      />
                      {errors["address"] && (
                        <p className="text-danger">Logradouro inválido</p>
                      )}
                    </Col>
                    <Col xs="3">
                      <Label className="col-form-label pt-0">Bairro</Label>
                      <Input
                        className={
                          errors["neighbor"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="Nome do Bairro"
                        value={neighbor}
                        onChange={(e) =>
                          setNeighbor(
                            e.target.value.charAt(0).toUpperCase() +
                              e.target.value.slice(1)
                          )
                        }
                      />
                      {errors["neighbor"] && (
                        <p className="text-danger">Bairro inválido</p>
                      )}
                    </Col>
                    <Col xs="2">
                      <Label className="col-form-label pt-0">Número</Label>
                      <Input
                        className={
                          errors["num"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="numero"
                        value={num}
                        onChange={(e) =>
                          setNum(e.target.value.replace(/\D/g, ""))
                        }
                      />
                      {errors["num"] && (
                        <p className="text-danger">Número inválido</p>
                      )}
                    </Col>
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="form-row">
                    <Col xs="6">
                      <Label className="col-form-label pt-0">Cidade</Label>
                      <Input
                        className={
                          errors["city"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="Nome da Cidade"
                        value={city}
                        onChange={(e) =>
                          setCity(
                            e.target.value.charAt(0).toUpperCase() +
                              e.target.value.slice(1)
                          )
                        }
                      />
                      {errors["city"] && (
                        <p className="text-danger">Cidade inválida</p>
                      )}
                    </Col>
                    <Col xs="4">
                      <Label className="col-form-label pt-0">CEP</Label>
                      <Input
                        className={
                          errors["cep"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="00000-000"
                        value={cep}
                        onChange={(e) => setCep(cepMask(e.target.value))}
                      />
                      {errors["cep"] && (
                        <p className="text-danger">CEP inválido</p>
                      )}
                    </Col>
                    <Col xs="2">
                      <Label className="col-form-label pt-0">Estado</Label>
                      <Input
                        className={
                          errors["stateAd"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="select"
                        required=""
                        placeholder=""
                        value={stateAd}
                        onChange={(e) => setStateAd(e.target.value)}
                      >
                        <option disabled></option>
                        <option>RN</option>
                        <option>PE</option>
                        <option>PR</option>
                      </Input>
                      {errors["stateAd"] && (
                        <p className="text-danger">UF inválido</p>
                      )}
                    </Col>
                  </div>
                </FormGroup>
                <h2>Tambores</h2>
                <FormGroup>
                  <div className="form-row">
                    <Col xs="8">
                      <Label>Nome do Tambor</Label>
                      <Input
                        className={
                          errorsBarrel["selBarrel"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="select"
                        required=""
                        placeholder=""
                        value={selBarrel}
                        onChange={(e) => setSelBarrel(e.target.value)}
                      >
                        <option disabled></option>
                        {barrelFromAPI.map((el) => {
                          return (
                            <option key={el.id_tambor} value={el.id_tambor}>
                              {el.nome}
                            </option>
                          );
                        })}
                      </Input>
                      {errorsBarrel["selBarrel"] && (
                        <p className="text-danger">Tambor inválido</p>
                      )}
                    </Col>
                    <Col xs="3">
                      <Label>Valor Cobrança</Label>
                      <InputGroup>
                        <InputGroupText
                          className={
                            errorsBarrel["chargeValue"]
                              ? "border-danger border-right-0"
                              : ""
                          }
                          style={{ borderRadius: "2px 0 0 2px" }}
                        >
                          R$
                        </InputGroupText>
                        <Input
                          className={
                            errorsBarrel["chargeValue"]
                              ? "form-control border-danger border-left-0"
                              : "form-control"
                          }
                          type="text"
                          required=""
                          placeholder="00,00"
                          value={chargeValue}
                          onChange={(e) =>
                            setChargeValue(numberMask(e.target.value))
                          }
                        />
                      </InputGroup>
                      {errorsBarrel["chargeValue"] && (
                        <p className="text-danger">Valor inválido</p>
                      )}
                    </Col>
                    <Col xs="1">
                      <Label style={{ visibility: "hidden" }}>Hidden</Label>
                      <Button onClick={handleAddBarrel}>+</Button>
                    </Col>
                  </div>
                </FormGroup>
                <FormGroup>
                  {barrels.length > 0 && (
                    <div className="form-row">
                      <Col xs="8">
                        <Label>ID</Label>
                      </Col>
                      <Col xs="4">
                        <Label>Valor Cobrança</Label>
                      </Col>
                    </div>
                  )}
                  {barrels.map((e, i) => {
                    return (
                      <div key={i + Math.random()} className="form-row mt-3">
                        <Col xs="8">
                          <Input
                            className="form-control"
                            type="text"
                            required=""
                            placeholder=""
                            value={e.nome}
                            disabled
                          />
                        </Col>
                        <Col xs="3">
                          <Input
                            className="form-control"
                            type="text"
                            required=""
                            placeholder=""
                            value={`R$ ${Number(e.valorCobranca).toFixed(2).replace('.',',')}`}
                            disabled
                          />
                        </Col>
                        <Col xs="1">
                          <Button
                            color="danger"
                            onClick={() => handleDelBarrel(i)}
                          >
                            x
                          </Button>
                        </Col>
                      </div>
                    );
                  })}
                </FormGroup>
                <FormGroup>
                  <Button
                    color="primary"
                    className="btn-block"
                    disabled={loading}
                  >
                    Atualizar Contrato
                  </Button>
                </FormGroup>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Create;
