import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
} from "reactstrap";
import { toast } from "react-toastify";
import { Redirect, useParams } from "react-router-dom";
import { api } from "../../api/axios";
import { dateMask, numberMask } from "../../utils/masks";
import Select from "react-select";

const Edit = (props) => {
  const [ok, setOk] = useState(false);

  const [vehicle, setVehicle] = useState({});
  const [vehicleFromAPI, setVehicleFromAPI] = useState([]);

  const [driver, setDriver] = useState("");
  const [driverFromAPI, setDriverFromAPI] = useState([]);

  const [date, setdate] = useState("");
  const [weight, setWeight] = useState("");
  const [avulse, setAvulse] = useState("");
  const [barrelFromAPI, setBarrelFromAPI] = useState([]);
  const [selBarrel, setSelBarrel] = useState("");
  const [barrels, setBarrels] = useState([]);
  const [avulseOp] = useState([
    { value: "sim", label: "Sim" },
    { value: "nao", label: "Não" },
  ]);

  const [errors, setErrors] = useState({});
  const [errorsBarrel, setErrorsBarrel] = useState({});
  const [loading, setLoading] = useState(false);

  const checkError = () => {
    let err = {};
    err["vehicle"] = vehicle === "";
    err["driver"] = driver === "";
    err["date"] = date.length < 6;
    setErrors(err);
    return Object.values(err).filter((e) => {
      return e;
    }).length;
  };
  const { id } = useParams();
  useEffect(() => {
    const func = async () => {
      const res = await api.get(`administrador/coleta/${id}`);
      const json = await res.data;
      setVehicle(json.coleta.veiculo.veiculo_id);
      setDriver(json.coleta.motorista.motorista_id);
      setAvulse(json.coleta.is_avulse ? "sim" : "nao");
      setdate(
        json.coleta.data_coleta.split("T")[0].split("-").reverse().join("/")
      );
      setBarrels(json.coleta.tambores);
    };
    if (id) func();
  }, [id]);

  useEffect(() => {
    const func = async () => {
      const res = await api.get(`administrador/tambores/select_tambores`);
      setBarrelFromAPI(
        res.data.tambores.map((el) => {
          return { value: el.id_tambor, label: el.nome };
        })
      );
    };
    func();
  }, []);

  useEffect(() => {
    const func = async () => {
      const res = await api.get(`administrador/motorista/select_motorista`);

      setDriverFromAPI(
        res.data.map((el) => {
          return { value: el.motorista_id, label: el.nome };
        })
      );
    };
    func();
  }, []);

  useEffect(() => {
    const func = async () => {
      const res = await api.get(`administrador/veiculo/select_veiculos`);

      setVehicleFromAPI(
        res.data.veiculos.map((el) => {
          return { value: el.veiculo_id, label: el.placa };
        })
      );
    };
    func();
  }, []);

  const handleAddBarrel = () => {
    let err = {};
    err["weight"] = weight.length < 2;
    err["selBarrel"] = selBarrel.length < 1;

    setErrorsBarrel(err);
    if (err.weight || err.selBarrel) return;

    setBarrels([
      ...barrels,
      {
        tambor_id: selBarrel,
        nome: barrelFromAPI.find((el) => el.value === selBarrel).label,
        peso: parseFloat(weight.replace(".", "").replace(",", ".")),
      },
    ]);
  };
  const handleDelBarrel = (i) => {
    let bar = [...barrels];
    bar.splice(i, 1);
    setBarrels(bar);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (checkError() > 0) return;
    setLoading(true);

    try {
      await api.patch(`administrador/coleta/${id}/atualizar`, {
        empresa_id: id,
        veiculo_id: vehicle,
        motorista_id: driver,
        data_coleta: date.split("/").reverse().join("-"),
        is_avulsa: avulse === "sim" ? true : false,
        tambores: barrels.map((e) => {
          delete e["nome"];
          delete e["nome_tambor"];
          return e;
        }),
      });
      setOk(true);
    } catch (err) {
      setLoading(false);
      if (err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Ops... Algo deu errado");
      }
    }
  };

  return (
    <Fragment>
      {ok && <Redirect to={`${process.env.PUBLIC_URL}/coletas/listar`} />}
      <Breadcrumb parent="Coletas" title="Atualizar Coleta" />
      <Container fluid={true}>
        <Row>
          <Col xs="12">
            <div className="card">
              <Form className="card-body" onSubmit={(e) => handleSubmit(e)}>
                <div className="form-row">
                  <Col xs="6">
                    <FormGroup>
                      <Label className="col-form-label">Motorista</Label>
                      <Select
                        value={driverFromAPI.filter((e) => {
                          return e.value === driver;
                        })}
                        options={driverFromAPI}
                        onChange={(e) => setDriver(e.value)}
                        noOptionsMessage={() => "Item não encontrado"}
                        placeholder={"Motorista"}
                        theme={(theme) => ({
                          ...theme,

                          colors: {
                            ...theme.colors,
                            primary: "#80bdff",
                          },
                        })}
                      />
                      {errors["driver"] && (
                        <p className="text-danger">Motorista Invalido</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <Label className="col-form-label">Veículo</Label>
                      <Select
                        value={vehicleFromAPI.filter((e) => {
                          return e.value === vehicle;
                        })}
                        options={vehicleFromAPI}
                        onChange={(e) => setVehicle(e.value)}
                        noOptionsMessage={() => "Item não encontrado"}
                        placeholder={"Veículo"}
                        theme={(theme) => ({
                          ...theme,

                          colors: {
                            ...theme.colors,
                            primary: "#80bdff",
                          },
                        })}
                      />
                      {errors["vehicle"] && (
                        <p className="text-danger">Veículo inválido</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <Label className="col-form-label">Data Coleta</Label>
                      <Input
                        className={
                          errors["date"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="00/00/000"
                        value={date}
                        onChange={(e) => setdate(dateMask(e.target.value))}
                      />
                      {errors["date"] && (
                        <p className="text-danger">Data inválida</p>
                      )}
                    </FormGroup>
                  </Col>

                  <Col xs="6">
                    <FormGroup>
                      <Label className="col-form-label">Avulsa</Label>
                      <Select
                        value={avulseOp.filter((e) => {
                          return e.value === avulse;
                        })}
                        options={avulseOp}
                        onChange={(e) => setAvulse(e.value)}
                        noOptionsMessage={() => "Item não encontrado"}
                        placeholder={"Avulsa?"}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#80bdff",
                          },
                        })}
                      />
                    </FormGroup>
                  </Col>
                </div>
                <h2>Tambores</h2>
                <FormGroup>
                  <div className="form-row">
                    <Col xs="8">
                      <Label>Nome do Tambor</Label>
                      <Select
                        value={barrelFromAPI.filter((e) => {
                          return e.value === selBarrel;
                        })}
                        options={barrelFromAPI}
                        onChange={(e) => setSelBarrel(e.value)}
                        noOptionsMessage={() => "Item não encontrado"}
                        placeholder={"Tambor"}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#80bdff",
                          },
                        })}
                      />
                      {errorsBarrel["selBarrel"] && (
                        <p className="text-danger">Tambor inválido</p>
                      )}
                    </Col>
                    <Col xs="3">
                      <Label>Peso</Label>
                      <InputGroup>
                        <Input
                          className={
                            errorsBarrel["chargeValue"]
                              ? "form-control border-danger border-left-0"
                              : "form-control"
                          }
                          type="text"
                          required=""
                          placeholder="00,00"
                          value={weight}
                          onChange={(e) =>
                            setWeight(numberMask(e.target.value))
                          }
                        />
                      </InputGroup>
                      {errorsBarrel["chargeValue"] && (
                        <p className="text-danger">Valor inválido</p>
                      )}
                    </Col>
                    <Col xs="1">
                      <Label style={{ visibility: "hidden" }}>Hidden</Label>
                      <Button onClick={handleAddBarrel}>+</Button>
                    </Col>
                  </div>
                </FormGroup>
                <FormGroup>
                  {barrels.length > 0 && (
                    <div className="form-row">
                      <Col xs="8">
                        <Label>Nome</Label>
                      </Col>
                      <Col xs="4">
                        <Label>Peso</Label>
                      </Col>
                    </div>
                  )}
                  {barrels.map((e, i) => {
                    return (
                      <div key={i + Math.random()} className="form-row mt-3">
                        <Col xs="8">
                          <Input
                            className="form-control"
                            type="text"
                            required=""
                            placeholder=""
                            value={e.nome_tambor}
                            disabled
                          />
                        </Col>
                        <Col xs="3">
                          <Input
                            className="form-control"
                            type="text"
                            required=""
                            placeholder=""
                            value={e.peso}
                            disabled
                          />
                        </Col>
                        <Col xs="1">
                          <Button
                            color="danger"
                            onClick={() => handleDelBarrel(i)}
                          >
                            x
                          </Button>
                        </Col>
                      </div>
                    );
                  })}
                </FormGroup>
                <div className="form-group mb-0">
                  <Button
                    color="primary"
                    className="btn-block"
                    type="submit"
                    disabled={loading}
                  >
                    Atualizar
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Edit;
