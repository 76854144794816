import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import ChartistChart from "react-chartist";
import {
  smallchart1data,
  smallchart1option,
} from "./chartsData/chartist-charts-data";
import { api } from "../../api/axios";

const Default = (props) => {
  const [charges, setCharges] = useState({});
  const [finan, setFinan] = useState({});
  const [generalData, setGeneralData] = useState({});

  useEffect(() => {
    const func = async () => {
      const res = await api.get(`administrador/dashboard/infoCobrancas`);
      const { dadosCobrancas, financas } = res.data;
      setCharges(dadosCobrancas);
      setFinan(financas);
    };
    func();
  }, []);
  useEffect(() => {
    const func = async () => {
      const res = await api.get(`administrador/dashboard/infoGeral`);
      const data = res.data;
      setGeneralData(data);
    };
    func();
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Alpha" />
      <Container fluid={true}>
        <Row>
          <Col xl="9 xl-100" className="chart_data_left box-col-12">
            <Card>
              <CardBody className="p-0">
                <Row className="m-0 chart-main">
                  <Col xl="4" md="12" sm="12" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart flot-chart-container"
                            data={smallchart1data}
                            options={smallchart1option}
                            type={"Bar"}
                            listener={{
                              draw: function (data) {
                                if (data.type === "bar") {
                                  data.element.attr({
                                    style: "stroke-width: 3px",
                                  });
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{generalData.totalEmpresas}</h4>
                          <span>Empresas</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="4" md="12" sm="12" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart1 flot-chart-container"
                            data={smallchart1data}
                            options={smallchart1option}
                            type={"Bar"}
                            listener={{
                              draw: function (data) {
                                if (data.type === "bar") {
                                  data.element.attr({
                                    style: "stroke-width: 3px",
                                  });
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{generalData.totalColetas}</h4>
                          <span>Coletas</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="4" md="12" sm="12" className="p-0 box-col-6">
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar">
                          <ChartistChart
                            className="small-chart2 flot-chart-container"
                            data={smallchart1data}
                            options={smallchart1option}
                            type={"Bar"}
                            listener={{
                              draw: function (data) {
                                if (data.type === "bar") {
                                  data.element.attr({
                                    style: "stroke-width: 3px",
                                  });
                                }
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <h4>{generalData.totalContrato}</h4>
                          <span>Contratos</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <h5>Cobranças</h5>
        <Row>
          <Col xl="4" className="chart_data_right box-col-12">
            <Card>
              <CardBody>
                <div className="media align-items-center">
                  <div className="media-body right-chart-content">
                    <h4>
                      {finan.totalAberto}
                      {/* <span className="new-box">{Hot}</span> */}
                    </h4>
                    <span>Cobranças em Aberto</span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4" className="chart_data_right box-col-12">
            <Card>
              <CardBody>
                <div className="media align-items-center">
                  <div className="media-body right-chart-content">
                    <h4>
                      {finan.totalPago}
                      {/* <span className="new-box">{Hot}</span> */}
                    </h4>
                    <span>Cobranças Pagas</span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4" className="chart_data_right second d-none">
            <Card>
              <CardBody>
                <div className="media align-items-center">
                  <div className="media-body right-chart-content">
                    <h4>
                      {finan.cobrancasVencidas}
                      {/* <span className="new-box">{New}</span> */}
                    </h4>
                    <span>Cobranças vencidas</span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl="4" className="chart_data_right box-col-12">
            <Card>
              <CardBody>
                <div className="media align-items-center">
                  <div className="media-body right-chart-content">
                    <h4>
                      {charges.cobrancasAberto}
                      {/* <span className="new-box">{Hot}</span> */}
                    </h4>
                    <span>Total em aberto</span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4" className="chart_data_right box-col-12">
            <Card>
              <CardBody>
                <div className="media align-items-center">
                  <div className="media-body right-chart-content">
                    <h4>
                      {charges.cobrancasPaga}
                      {/* <span className="new-box">{Hot}</span> */}
                    </h4>
                    <span>Total pago</span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4" className="chart_data_right second d-none">
            <Card>
              <CardBody>
                <div className="media align-items-center">
                  <div className="media-body right-chart-content">
                    <h4>
                      {charges.cobrancasVencidas}
                      {/* <span className="new-box">{New}</span> */}
                    </h4>
                    <span>Total vencidas</span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Default;
