import React, { useState, Fragment } from "react";

import download from "js-file-download";

// import { Redirect } from "react-router";
import {
  Breadcrumb,
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { downloadPDF } from "../../api/axios";
import { toast } from "react-toastify";

const Extract = () => {
  // const [ok, setOk] = useState(false);
  const [errors, setErrors] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);

  const checkError = () => {
    let sd = new Date(startDate);
    let ed = new Date(endDate);

    let err = {};
    err.startDate = startDate.length < 10;
    err.endDate = endDate.length < 10;
    err.date = sd - ed >= 0;
    setErrors(err);
    return Object.values(err).filter((e) => {
      return e;
    }).length;
  };

  const handleGenerateExtract = async (e) => {
    e.preventDefault();
    if (checkError() > 0) return;
    setLoading(true);

    try {
      const res = await downloadPDF.get(
        `administrador/relatorios/extrato?data_inicio=${startDate
          .split("/")
          .reverse()
          .join("-")}&data_fim=${endDate
          .split("/")
          .reverse()
          .join("-")}&status=1`
      );
      toast.success("Relatório gerado com sucesso!");
      download(res.data, "relatorio.pdf");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Ops... Algo deu errado");
      }
    }
  };

  return (
    <Fragment>
      {/* {ok && <Redirect to={`${process.env.PUBLIC_URL}/`} />} */}
      <Breadcrumb parent="Tambores" title="Cadastrar Tambor" />
      <Container fluid={true}>
        <Row>
          <Col xs="12">
            <div className="card">
              <Form
                className="card-body"
                onSubmit={(e) => handleGenerateExtract(e)}
              >
                <div className="form-row">
                  <Col xs="6">
                    <FormGroup>
                      <Label className="col-form-label">Data início</Label>
                      <Input
                        className={
                          errors["startDate"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="date"
                        required=""
                        // placeholder="00/00/0000"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                      {errors["startDate"] && (
                        <p className="text-danger">Data inválida</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <Label className="col-form-label">Data Fim</Label>
                      <Input
                        className={
                          errors["endDate"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="date"
                        required=""
                        placeholder="00/00/0000"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                      {errors["endDate"] && (
                        <p className="text-danger">Data inválida</p>
                      )}
                    </FormGroup>
                  </Col>
                </div>
                {errors["date"] && (
                  <p className="text-danger">
                    Data Início deve ser antes da Data Fim
                  </p>
                )}
                <div className="form-group mb-0">
                  <Button
                    color="primary"
                    className="btn-block"
                    type="submit"
                    disabled={loading}
                  >
                    Gerar Extrato
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Extract;
