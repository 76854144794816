import React, { useEffect, useState } from "react";

import { api } from "../../api/axios";

import { Link, Redirect, useParams } from "react-router-dom";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { dateMask, cepMask, numberMask } from "../../utils/masks";
import { toast } from "react-toastify";
import Select from "react-select";

const View = (props) => {
  const [fantasyName, setFantasyName] = useState("");
  const [social, setSocial] = useState("");
  // const [email, setEmail] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [phone, setPhone] = useState("");
  // Contract
  const [chargeType, setChargeType] = useState("");
  const [excedentValue, setExcedentValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dueDay, setDueDay] = useState("");
  const [collectFreq, setCollectFreq] = useState("");
  const [minQuant, setMinQuant] = useState("");
  const [minMValue, setMinMValue] = useState("");
  const [minAValue, setMinAValue] = useState("");
  const [chargeValue, setChargeValue] = useState("");
  const [barrelFromAPI, setBarrelFromAPI] = useState([]);
  const [selBarrel, setSelBarrel] = useState("");
  const [barrels, setBarrels] = useState([]);
  // Address
  const [fullAd, setFullAd] = useState({});
  const [address, setAddress] = useState("");
  const [num, setNum] = useState("");
  const [neighbor, setNeighbor] = useState("");
  const [city, setCity] = useState("");
  const [stateAd, setStateAd] = useState("");
  const [cep, setCep] = useState("");
  const [errors, setErrors] = useState({});
  const [errorsBarrel, setErrorsBarrel] = useState({});
  const [chargeOp] = useState([
    { value: "Kilo", label: "Kilo" },
    { value: "Tambor", label: "Tambor" },
  ]);
  const [states] = useState([
    { value: "RN", label: "RN" },
    { value: "PB", label: "PB" },
    { value: "CE", label: "CE" },
  ]);
  //
  const [week, setWeek] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [ok, setOk] = useState(false);

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { id } = useParams();
  useEffect(() => {
    const func = async () => {
      const res = await api.get(`administrador/empresas/${id}`);
      const json = await res.data;

      setFantasyName(json.nome_fantasia);
      setSocial(json.razao_social);
      setCnpj(json.cnpj_cpf);
      setPhone(json.telefone);
      setFullAd(json.endereco);
      setAddress(json.endereco.logradouro);
      setNeighbor(json.endereco.bairro);
      setNum(json.endereco.numero);
      setCity(json.endereco.cidade);
      setCep(json.endereco.cep);
      setStateAd(json.endereco.estado);
    };
    if (id) func();
  }, [id]);
  useEffect(() => {
    const func = async () => {
      const res = await api.get(
        `administrador/contratos?page=0&enable=true&query=${id}`
      );
      setContracts(res.data.contratos);
    };
    if (id) func();
  }, [id]);

  useEffect(() => {
    const func = async () => {
      const res = await api.get(`administrador/tambores/select_tambores`);
      setBarrelFromAPI(
        res.data.tambores.map((el) => {
          return { value: el.id_tambor, label: el.nome };
        })
      );
    };
    func();
  }, []);

  const handleAddBarrel = () => {
    let err = {};
    err["chargeValue"] = chargeValue.length < 2;
    err["selBarrel"] = selBarrel.length < 1;

    setErrorsBarrel(err);
    if (err.chargeValue || err.selBarrel) return;

    setBarrels([
      ...barrels,
      {
        tambor_id: selBarrel,
        nome: barrelFromAPI.find((el) => el.value === selBarrel).label,
        valorCobranca: chargeValue,
      },
    ]);
  };

  const handleDelBarrel = (i) => {
    let bar = [...barrels];
    bar.splice(i, 1);
    setBarrels(bar);
  };

  const checkError = () => {
    let err = {};

    err["chargeType"] = chargeType === "";
    // err["contractType"] = contractType === "";
    err["excedentValue"] = excedentValue === "" || Number(excedentValue) < 0;
    err["collectFreq"] = collectFreq === "" || Number(collectFreq) < 0;
    err["startDate"] = startDate.length < 10;
    err["endDate"] = endDate.length < 10;
    err["dueDay"] = dueDay === "" || Number(dueDay) < 1;
    err["minQuant"] = minQuant === "" || Number(minQuant) < 0;
    err["minMValue"] = minMValue === "" || Number(minMValue) < 0;
    err["minAValue"] = minAValue === "" || Number(minAValue) < 0;
    err["address"] = address.length < 5;
    err["neighbor"] = neighbor.length < 5;
    err["city"] = city.length < 5;
    err["cep"] = !/^\d{5}-\d{3}$/.test(cep);
    err["stateAd"] = stateAd.length < 1;
    err["num"] = num.length < 1;

    setErrors(err);
    return Object.values(err).filter((e) => {
      return e;
    }).length;
  };

  const handleCreateContract = async (e) => {
    e.preventDefault();
    if (checkError() > 0) return;

    const addr = {
      cep: cep,
      bairro: neighbor,
      cidade: city,
      estado: stateAd,
      logradouro: address,
      numero: num,
    };
    try {
      await api.post(`administrador/contrato/cadastrar`, {
        tipoContrato: 0,
        empresa_id: id,
        contrato_id: "",
        tipoCobranca: chargeType === "Kilo" ? 0 : 1,
        valorExcedente: Number(
          excedentValue.replace(".", "").replace(",", ".")
        ),
        enderecoColeta: addr,
        enderecoContrato: addr,
        dataInicio: startDate,
        dataFim: endDate,
        diaVencimento: Number(dueDay),
        qtMinimaColeta: Number(minQuant),
        valorMinimoMensal: Number(minMValue.replace(".", "").replace(",", ".")),
        valorMinimoAnual: Number(minAValue.replace(".", "").replace(",", ".")),
        frequenciaColeta: Number(collectFreq),
        tambores: barrels.map((e) => {
          delete e["nome"];
          delete e["descricao"];
          e["valorCobranca"] = Number(
            e["valorCobranca"].replace(".", "").replace(",", ".")
          );
          return e;
        }),
        diaSemana: week,
      });
      setOk(true);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  return (
    <>
      {ok && <Redirect to={`${process.env.PUBLIC_URL}/contratos/listar`} />}
      <Breadcrumb parent="Empresas" title="Visualizar Empresa" />
      <Container fluid={true}>
        <Link
          to={`${process.env.PUBLIC_URL}/coletas/cadastrar/${id}`}
          className="btn btn-primary float-right"
        >
          Nova Coleta
        </Link>
        <Nav tabs>
          <NavItem style={{ cursor: "pointer" }}>
            <NavLink
              className={classnames(
                { active: activeTab === "1" },
                "border",
                "border-bottom-0"
              )}
              onClick={() => {
                toggle("1");
              }}
            >
              Detalhes
            </NavLink>
          </NavItem>
          <NavItem style={{ cursor: "pointer" }}>
            <NavLink
              className={classnames(
                { active: activeTab === "2" },
                "border",
                "border-bottom-0"
              )}
              onClick={() => {
                toggle("2");
              }}
            >
              Contrato
            </NavLink>
          </NavItem>
        </Nav>

        <Card className="p-30" style={{ borderRadius: "0px 15px 15px 15px" }}>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col>
                  <p>
                    <b style={{ color: "#5cad97" }}>Nome Fantasia: </b>
                    {fantasyName}
                  </p>
                  <p>
                    <b style={{ color: "#5cad97" }}>Razão Social: </b>
                    {social}
                  </p>
                </Col>
                <Col>
                  <p>
                    <b style={{ color: "#5cad97" }}>CPF/CNPJ: </b>
                    {cnpj}
                  </p>
                  <p>
                    <b style={{ color: "#5cad97" }}>Telefone: </b>
                    {phone}
                  </p>
                </Col>
              </Row>
              <h5>Endereco</h5>
              <Row>
                <Col>
                  <p>
                    <b style={{ color: "#5cad97" }}>Rua: </b>
                    {fullAd.logradouro}
                  </p>
                  <p>
                    <b style={{ color: "#5cad97" }}>Bairro: </b>
                    {fullAd.bairro}
                  </p>
                  <p>
                    <b style={{ color: "#5cad97" }}>Estado: </b>
                    {fullAd.estado}
                  </p>
                </Col>
                <Col>
                  <p>
                    <b style={{ color: "#5cad97" }}>Cidade: </b>
                    {fullAd.cidade}
                  </p>
                  <p>
                    <b style={{ color: "#5cad97" }}>CEP: </b>
                    {fullAd.cep}
                  </p>
                  <p>
                    <b style={{ color: "#5cad97" }}>Numero: </b>
                    {fullAd.numero}
                  </p>
                </Col>
              </Row>
              {contracts.length > 0 && (
                <>
                  <h5>Contratos</h5>
                  <Row>
                    {contracts.map((e) => {
                      return (
                        <Row key={e.contrato_id}>
                          <Col>
                            <p>{e.contrato_id}</p>
                          </Col>
                        </Row>
                      );
                    })}
                  </Row>
                </>
              )}
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Form
                  className="card-body"
                  onSubmit={(e) => handleCreateContract(e)}
                >
                  <FormGroup>
                    <div className="form-row">
                      <Col xs="4">
                        <Label className="col-form-label pt-0">
                          Tipo da Cobrança
                        </Label>
                        <Select
                          value={chargeOp.filter((e) => {
                            return e.value === chargeType;
                          })}
                          options={chargeOp}
                          onChange={(e) => setChargeType(e.value)}
                          noOptionsMessage={() => "Item não encontrado"}
                          placeholder={"Tipo Coleta?"}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "#80bdff",
                            },
                          })}
                        />
                        {errors["chargeType"] && (
                          <p className="text-danger">
                            Tipo de cobrança inválido
                          </p>
                        )}
                      </Col>
                      <Col xs="4">
                        <Label className="col-form-label pt-0">
                          Valor Excedente
                        </Label>
                        <InputGroup>
                          <InputGroupText
                            className={
                              errors["excedentValue"]
                                ? "border-danger border-right-0"
                                : ""
                            }
                            style={{ borderRadius: "2px 0 0 2px" }}
                          >
                            R$
                          </InputGroupText>
                          <Input
                            className={
                              errors["excedentValue"]
                                ? "form-control border-danger border-left-0"
                                : "form-control"
                            }
                            type="text"
                            required=""
                            placeholder="00,00"
                            value={excedentValue}
                            onChange={(e) =>
                              setExcedentValue(numberMask(e.target.value))
                            }
                          />
                        </InputGroup>
                        {errors["excedentValue"] && (
                          <p className="text-danger">
                            Valor excedente inválido
                          </p>
                        )}
                      </Col>
                      <Col xs="4">
                        <Label className="col-form-label pt-0">
                          Frequência da coleta
                        </Label>
                        <Input
                          className={
                            errors["collectFreq"]
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          type="text"
                          required=""
                          placeholder="0"
                          value={collectFreq}
                          onChange={(e) => setCollectFreq(e.target.value)}
                        />
                        {errors["collectFreq"] && (
                          <p className="text-danger">
                            Frequência da coleta Inválida
                          </p>
                        )}
                      </Col>
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <div className="form-row">
                      <Col xs="4">
                        <Label className="col-form-label pt-0">
                          Data de Início
                        </Label>
                        <Input
                          className={
                            errors["startDate"]
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          type="text"
                          required=""
                          placeholder="00/00/0000"
                          value={startDate}
                          onChange={(e) =>
                            setStartDate(dateMask(e.target.value))
                          }
                        />
                        {errors["startDate"] && (
                          <p className="text-danger">Data inválida</p>
                        )}
                      </Col>
                      <Col xs="4">
                        <Label className="col-form-label pt-0">
                          Data fim da vigência
                        </Label>
                        <Input
                          className={
                            errors["endDate"]
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          type="text"
                          required=""
                          placeholder="00/00/0000"
                          value={endDate}
                          onChange={(e) => setEndDate(dateMask(e.target.value))}
                        />
                        {errors["endDate"] && (
                          <p className="text-danger">Data inválida</p>
                        )}
                      </Col>
                      <Col xs="4">
                        <Label className="col-form-label pt-0">
                          Dia do Vencimento
                        </Label>
                        <Input
                          className={
                            errors["dueDay"]
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          type="text"
                          required=""
                          placeholder="00"
                          value={dueDay}
                          onChange={(e) => setDueDay(e.target.value)}
                        />
                        {errors["dueDay"] && (
                          <p className="text-danger">Dia inválido</p>
                        )}
                      </Col>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="form-row">
                      <Col xs="12">
                        <Label>SEMANA</Label>
                        <Select
                          onChange={(e) => {
                            setWeek(
                              e.map((el) => {
                                return { dia: el.value };
                              })
                            );
                          }}
                          isMulti
                          options={[
                            { value: 1, label: "Domingo" },
                            { value: 2, label: "Segunda" },
                            { value: 3, label: "Terça" },
                            { value: 4, label: "Quarta" },
                            { value: 5, label: "Quinta" },
                            { value: 6, label: "Sexta" },
                            { value: 7, label: "Sabado" },
                          ]}
                        />
                      </Col>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="form-row">
                      <Col xs="4">
                        <Label className="col-form-label pt-0">
                          Quantidade Mínima
                        </Label>
                        <Input
                          className={
                            errors["minQuant"]
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          type="text"
                          required=""
                          placeholder="0"
                          value={minQuant}
                          onChange={(e) => setMinQuant(e.target.value)}
                        />
                        {errors["minQuant"] && (
                          <p className="text-danger">Valor inválido</p>
                        )}
                      </Col>
                      <Col xs="4">
                        <Label className="col-form-label pt-0">
                          Valor Mínimo Mensal
                        </Label>
                        <InputGroup>
                          <InputGroupText
                            className={
                              errors["minMValue"]
                                ? "border-danger border-right-0"
                                : ""
                            }
                            style={{ borderRadius: "2px 0 0 2px" }}
                          >
                            R$
                          </InputGroupText>
                          <Input
                            className={
                              errors["minMValue"]
                                ? "form-control border-danger border-left-0"
                                : "form-control"
                            }
                            type="text"
                            required=""
                            placeholder="00,00"
                            value={minMValue}
                            onChange={(e) =>
                              setMinMValue(numberMask(e.target.value))
                            }
                          />
                        </InputGroup>
                        {errors["minMValue"] && (
                          <p className="text-danger">Valor inválido</p>
                        )}
                      </Col>
                      <Col xs="4">
                        <Label className="col-form-label pt-0">
                          Valor Mínimo Anual
                        </Label>
                        <InputGroup>
                          <InputGroupText
                            className={
                              errors["minAValue"] &&
                              "border-danger border-right-0"
                            }
                            style={{ borderRadius: "2px 0 0 2px" }}
                          >
                            R$
                          </InputGroupText>
                          <Input
                            className={
                              errors["minAValue"]
                                ? "form-control border-danger border-left-0"
                                : "form-control"
                            }
                            type="text"
                            required=""
                            placeholder="00,00"
                            value={minAValue}
                            onChange={(e) =>
                              setMinAValue(numberMask(e.target.value))
                            }
                          />
                        </InputGroup>
                        {errors["minAValue"] && (
                          <p className="text-danger">
                            Quantidade mínima inválida
                          </p>
                        )}
                      </Col>
                    </div>
                  </FormGroup>
                  <h2>Endereço da Coleta</h2>
                  <FormGroup>
                    <div className="form-row">
                      <Col xs="7">
                        <Label className="col-form-label pt-0">
                          Logradouro
                        </Label>
                        <Input
                          className={
                            errors["address"]
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          type="text"
                          required=""
                          placeholder="Nome da rua"
                          value={address}
                          onChange={(e) =>
                            setAddress(
                              e.target.value.charAt(0).toUpperCase() +
                                e.target.value.slice(1)
                            )
                          }
                        />
                        {errors["address"] && (
                          <p className="text-danger">Logradouro inválido</p>
                        )}
                      </Col>
                      <Col xs="3">
                        <Label className="col-form-label pt-0">Bairro</Label>
                        <Input
                          className={
                            errors["neighbor"]
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          type="text"
                          required=""
                          placeholder="Nome do Bairro"
                          value={neighbor}
                          onChange={(e) =>
                            setNeighbor(
                              e.target.value.charAt(0).toUpperCase() +
                                e.target.value.slice(1)
                            )
                          }
                        />
                        {errors["neighbor"] && (
                          <p className="text-danger">Bairro inválido</p>
                        )}
                      </Col>
                      <Col xs="2">
                        <Label className="col-form-label pt-0">Número</Label>
                        <Input
                          className={
                            errors["num"]
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          type="text"
                          required=""
                          placeholder="numero"
                          value={num}
                          onChange={(e) =>
                            setNum(e.target.value.replace(/\D/g, ""))
                          }
                        />
                        {errors["num"] && (
                          <p className="text-danger">Número inválido</p>
                        )}
                      </Col>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div className="form-row">
                      <Col xs="6">
                        <Label className="col-form-label pt-0">Cidade</Label>
                        <Input
                          className={
                            errors["city"]
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          type="text"
                          required=""
                          placeholder="Nome da Cidade"
                          value={city}
                          onChange={(e) =>
                            setCity(
                              e.target.value.charAt(0).toUpperCase() +
                                e.target.value.slice(1)
                            )
                          }
                        />
                        {errors["city"] && (
                          <p className="text-danger">Cidade inválida</p>
                        )}
                      </Col>
                      <Col xs="4">
                        <Label className="col-form-label pt-0">CEP</Label>
                        <Input
                          className={
                            errors["cep"]
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          type="text"
                          required=""
                          placeholder="00000-000"
                          value={cep}
                          onChange={(e) => setCep(cepMask(e.target.value))}
                        />
                        {errors["cep"] && (
                          <p className="text-danger">CEP inválido</p>
                        )}
                      </Col>
                      <Col xs="2">
                        <Label className="col-form-label pt-0">Estado</Label>
                        <Select
                          value={states.filter((e) => {
                            return e.value === stateAd;
                          })}
                          options={states}
                          onChange={(e) => setStateAd(e.value)}
                          noOptionsMessage={() => "Item não encontrado"}
                          placeholder={"UF"}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "#80bdff",
                            },
                          })}
                        />
                        {errors["stateAd"] && (
                          <p className="text-danger">UF inválido</p>
                        )}
                      </Col>
                    </div>
                  </FormGroup>
                  <h2>Tambores</h2>
                  <FormGroup>
                    <div className="form-row">
                      <Col xs="8">
                        <Label>Nome do Tambor</Label>
                        <Select
                          value={barrelFromAPI.filter((e) => {
                            return e.value === selBarrel;
                          })}
                          options={barrelFromAPI}
                          onChange={(e) => setSelBarrel(e.value)}
                          noOptionsMessage={() => "Item não encontrado"}
                          placeholder={"Tambor"}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: "#80bdff",
                            },
                          })}
                        />
                        {errorsBarrel["selBarrel"] && (
                          <p className="text-danger">Tambor inválido</p>
                        )}
                      </Col>
                      <Col xs="3">
                        <Label>Valor Cobrança</Label>
                        <InputGroup>
                          <InputGroupText
                            className={
                              errorsBarrel["chargeValue"]
                                ? "border-danger border-right-0"
                                : ""
                            }
                            style={{ borderRadius: "2px 0 0 2px" }}
                          >
                            R$
                          </InputGroupText>
                          <Input
                            className={
                              errorsBarrel["chargeValue"]
                                ? "form-control border-danger border-left-0"
                                : "form-control"
                            }
                            type="text"
                            required=""
                            placeholder="00,00"
                            value={chargeValue}
                            onChange={(e) =>
                              setChargeValue(numberMask(e.target.value))
                            }
                          />
                        </InputGroup>
                        {errorsBarrel["chargeValue"] && (
                          <p className="text-danger">Valor inválido</p>
                        )}
                      </Col>
                      <Col xs="1">
                        <Label style={{ visibility: "hidden" }}>Hidden</Label>
                        <Button onClick={handleAddBarrel}>+</Button>
                      </Col>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    {barrels.length > 0 && (
                      <div className="form-row">
                        <Col xs="8">
                          <Label>ID</Label>
                        </Col>
                        <Col xs="4">
                          <Label>Valor Cobrança</Label>
                        </Col>
                      </div>
                    )}
                    {barrels.map((e, i) => {
                      return (
                        <div key={i + Math.random()} className="form-row mt-3">
                          <Col xs="8">
                            <Input
                              className="form-control"
                              type="text"
                              required=""
                              placeholder=""
                              value={e.nome}
                              disabled
                            />
                          </Col>
                          <Col xs="3">
                            <Input
                              className="form-control"
                              type="text"
                              required=""
                              placeholder=""
                              value={e.valorCobranca}
                              disabled
                            />
                          </Col>
                          <Col xs="1">
                            <Button
                              color="danger"
                              onClick={() => handleDelBarrel(i)}
                            >
                              x
                            </Button>
                          </Col>
                        </div>
                      );
                    })}
                  </FormGroup>
                  <FormGroup>
                    <Button color="primary" className="btn-block">
                      Inserir Contrato
                    </Button>
                  </FormGroup>
                </Form>
              </Row>
            </TabPane>
          </TabContent>
        </Card>
      </Container>
    </>
  );
};

export default View;
