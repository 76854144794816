import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";
import { Redirect, useParams } from "react-router-dom";
import { cpfMask, cnpjMask, cepMask, phoneMask } from "../../utils/masks";
import { api } from "../../api/axios";
import Select from "react-select";

const Edit = (props) => {
  const [ok, setOk] = useState(false);
  const [fantasyName, setFantasyName] = useState("");
  const [social, setSocial] = useState("");
  const [email, setEmail] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [address, setAddress] = useState("");
  const [num, setNum] = useState("");
  const [stateAd, setStateAd] = useState("");
  const [neigh, setNeigh] = useState("");
  const [city, setCity] = useState("");
  const [cep, setCep] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({});
  const [states] = useState([
    { value: "RN", label: "RN" },
    { value: "PB", label: "PB" },
    { value: "CE", label: "CE" },
  ]);
  const [loading, setLoading] = useState(false);

  const checkError = () => {
    let err = {};
    err["fantasyName"] = fantasyName.length < 3;
    err["social"] = social.length < 5;
    err["email"] = !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
    err["cnpj"] =
      !/(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/.test(
        cnpj
      );
    err["address"] = address.length < 10;
    err["num"] = num.length < 1;
    err["stateAd"] = stateAd.length < 2;
    err["neigh"] = neigh.length < 5;
    err["city"] = city.length < 4;
    err["cep"] = !/^\d{5}-\d{3}$/.test(cep);
    err["phone"] = !/(\(\d{2}\)\s)?(\d{4,5}-\d{4})/.test(phone);

    setErrors(err);
    return Object.values(err).filter((e) => {
      return e;
    }).length;
  };

  const { id } = useParams();
  useEffect(() => {
    const func = async () => {
      const res = await api.get(`administrador/empresas/${id}`);
      const json = await res.data;

      setFantasyName(json.nome_fantasia);
      setSocial(json.razao_social);
      setEmail(json.email);
      setCnpj(json.cnpj_cpf);
      setAddress(json.endereco.logradouro);
      setNum(json.endereco.numero);
      setStateAd(json.endereco.estado);
      setNeigh(json.endereco.bairro);
      setCity(json.endereco.cidade);
      setCep(json.endereco.cep);
      setPhone(json.telefone);
      console.log(json);
    };
    if (id) func();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (checkError() > 0) return;
    setLoading(true);

    try {
      await api.patch(`administrador/empresas/${id}/atualizar`, {
        nome_fantasia: fantasyName,
        razao_social: social,
        telefone: phone,
        email: email,
        cnpj_cpf: cnpj,
        rua: address,
        numero: num,
        estado: stateAd,
        bairro: neigh,
        cidade: city,
        cep: cep,
      });
      setOk(true);
    } catch (err) {
      setLoading(false);
      if (err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Ops... Algo deu errado");
      }
    }
  };

  const handleCnpjChange = (e) => {
    let val = e.target.value;
    val.length <= 14 ? setCnpj(cpfMask(val)) : setCnpj(cnpjMask(val));
  };

  return (
    <Fragment>
      {ok && <Redirect to={`${process.env.PUBLIC_URL}/empresas/listar`} />}
      <Breadcrumb parent="Empresas" title="Atualizar Empresa" />
      <Container fluid={true}>
        <Row>
          <Col xs="12">
            <div className="card">
              <Form className="card-body" onSubmit={(e) => handleSubmit(e)}>
                <FormGroup>
                  <div className="form-row">
                    <Col xs="6">
                      <Label className="col-form-label pt-0">
                        Nome Fantasia
                      </Label>
                      <Input
                        className={
                          errors["fantasyName"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="Nome Fantasia"
                        value={fantasyName}
                        onChange={(e) =>
                          setFantasyName(
                            e.target.value.charAt(0).toUpperCase() +
                              e.target.value.slice(1)
                          )
                        }
                      />
                      {errors["fantasyName"] && (
                        <p className="text-danger">Nome fantasia inválido</p>
                      )}
                    </Col>
                    <Col xs="6">
                      <Label className="col-form-label pt-0">
                        Razão Social
                      </Label>
                      <Input
                        className={
                          errors["social"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="Razão Social"
                        value={social}
                        onChange={(e) =>
                          setSocial(
                            e.target.value.charAt(0).toUpperCase() +
                              e.target.value.slice(1)
                          )
                        }
                      />
                      {errors["social"] && (
                        <p className="text-danger">Razão social inválida</p>
                      )}
                    </Col>
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="form-row">
                    <Col xs="6">
                      <Label className="col-form-label pt-0">Email</Label>
                      <Input
                        className={
                          errors["email"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="E-mail"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {errors["email"] && (
                        <p className="text-danger">E-mail inválido</p>
                      )}
                    </Col>
                    <Col xs="6">
                      <Label className="col-form-label pt-0">CPF/CNPJ</Label>
                      <Input
                        className={
                          errors["cnpj"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="000.000.000-00"
                        value={cnpj}
                        onChange={(e) => handleCnpjChange(e)}
                      />
                      {errors["cnpj"] && (
                        <p className="text-danger">CPF/CNPJ inválido</p>
                      )}
                    </Col>
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="form-row">
                    <Col xs="10">
                      <Label className="col-form-label pt-0">Logradouro</Label>
                      <Input
                        className={
                          errors["address"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="Nome da Rua"
                        value={address}
                        onChange={(e) =>
                          setAddress(
                            e.target.value.charAt(0).toUpperCase() +
                              e.target.value.slice(1)
                          )
                        }
                      />
                      {errors["address"] && (
                        <p className="text-danger">Logradouro inválido</p>
                      )}
                    </Col>
                    <Col xs="2">
                      <Label className="col-form-label pt-0">Numero</Label>
                      <Input
                        className={
                          errors["num"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="number"
                        required=""
                        placeholder="000"
                        value={num}
                        onChange={(e) => setNum(e.target.value)}
                      />
                      {errors["num"] && (
                        <p className="text-danger">Número inválido</p>
                      )}
                    </Col>
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="form-row">
                    <Col xs="5">
                      <Label className="col-form-label pt-0">Cidade</Label>
                      <Input
                        className={
                          errors["city"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="Cidade"
                        value={city}
                        onChange={(e) =>
                          setCity(
                            e.target.value.charAt(0).toUpperCase() +
                              e.target.value.slice(1)
                          )
                        }
                      />
                      {errors["city"] && (
                        <p className="text-danger">Cidade inválida</p>
                      )}
                    </Col>
                    <Col xs="5">
                      <Label className="col-form-label pt-0">Bairro</Label>
                      <Input
                        className={
                          errors["neigh"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="Bairro"
                        value={neigh}
                        onChange={(e) =>
                          setNeigh(
                            e.target.value.charAt(0).toUpperCase() +
                              e.target.value.slice(1)
                          )
                        }
                      />
                      {errors["neigh"] && (
                        <p className="text-danger">Bairro inválido</p>
                      )}
                    </Col>
                    <Col xs="2">
                      <Label className="col-form-label pt-0">Estado</Label>
                      <Select
                        value={states.filter((e) => {
                          return e.value === stateAd;
                        })}
                        options={states}
                        onChange={(e) => setStateAd(e.value)}
                        noOptionsMessage={() => "Item não encontrado"}
                        placeholder={"UF"}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#80bdff",
                          },
                        })}
                      />
                      {errors["stateAd"] && (
                        <p className="text-danger">UF inválido</p>
                      )}
                    </Col>
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="form-row">
                    <Col xs="6">
                      <Label className="col-form-label pt-0">CEP</Label>
                      <Input
                        className={
                          errors["cep"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="00000-000"
                        value={cep}
                        onChange={(e) => setCep(cepMask(e.target.value))}
                      />
                      {errors["cep"] && (
                        <p className="text-danger">CEP inválido</p>
                      )}
                    </Col>
                    <Col xs="6">
                      <Label className="col-form-label pt-0">Telefone</Label>
                      <Input
                        className={
                          errors["phone"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="tel"
                        required=""
                        placeholder="(00) 0.0000-0000"
                        value={phone}
                        onChange={(e) => setPhone(phoneMask(e.target.value))}
                      />
                      {errors["phone"] && (
                        <p className="text-danger">Telefone inválido</p>
                      )}
                    </Col>
                  </div>
                </FormGroup>
                <div className="form-group mb-0">
                  <Button
                    color="primary"
                    className="btn-block"
                    type="submit"
                    disabled={loading}
                  >
                    Atualizar
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Edit;
