import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import Breadcrumb from "../../layout/breadcrumb";
import { Card, Col, Container, Row } from "reactstrap";
import { api } from "../../api/axios";

const View = (props) => {
  const { id } = useParams();
  const [vehicle, setVehicle] = useState({});

  useEffect(() => {
    const func = async () => {
      const res = await api.get(`administrador/veiculos/${id}`);
      setVehicle(res.data[0]);
    };
    if (id) func();
  }, [id]);

  return (
    <>
      <Breadcrumb parent="Veículos" title="Detalhes do Veículo" />
      <Container fluid={true}>
        <Card className="p-30" style={{ borderRadius: "0px 15px 15px 15px" }}>
          <Row>
            <Col>
              <p>
                <b style={{ color: "#5cad97" }}>Modelo: </b> {vehicle.modelo}
              </p>
              <p>
                <b style={{ color: "#5cad97" }}>Placa: </b> {vehicle.placa}
              </p>
            </Col>
            <Col>
              <p>
                <b style={{ color: "#5cad97" }}>Cor: </b>
                {vehicle.cor}
              </p>
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  );
};

export default View;
