import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Card,
  Col,
  InputGroupText,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  PaginationItem,
  PaginationLink,
  Pagination,
  UncontrolledTooltip
} from "reactstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";

import { Edit, Eye, Search, Trash2 } from "react-feather";
import { toast } from "react-toastify";
import { api } from "../../api/axios";
import { Next, Previous } from "../../constant";
import {
  handlePrevPageChange,
  leftButtonClick,
  leftButtonText,
  middleButtonActive,
  middleButtonClick,
  middleButtonText,
  rightButtonClick,
  rightButtonText,
} from "../../utils/pagination";

const List = (props) => {
  const [collects, setCollects] = useState([]);
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState("");
  const [refre, setRefre] = useState(false);
  const [page, setPage] = useState(1);
  const [pgCount, setPgCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [companyFromAPI, setCompanyFromAPI] = useState([]);

  useEffect(() => {
    const func = async () => {
      const res = await api.get(`administrador/cobranca/empresas`);
      setCompanyFromAPI(
        res.data.empresas.map((el) => {
          return { value: el.id_empresa, label: el.nome_fantasia };
        })
      );
    };
    func();
  }, []);

  useEffect(() => {
    const func = async () => {
      setLoading(true);
      const collects = await api.get(
        `administrador/coleta?page=${page}&enable=true`
      );
      setCollects(collects.data.coletas);
      setPgCount(collects.data.pagination.total_pages);
      setLoading(false);
    };
    func();
  }, [refre, page]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      setLoading(true);
      const res = await api.get(
        `administrador/coleta?page=0&enable=true&empresa_id=${search}`
      );
      setCollects(res.data.coletas);
      setLoading(false);
    }, 200);
    return () => clearTimeout(timer);
  }, [search]);
  // useEffect(() => {
  //   const timer = setTimeout(async () => {
  //     setLoading(true);
  //     const res = await api.get(
  //       `administrador/coleta?page=0&enable=true&query=${search}`
  //     );
  //     setCollects(res.data.coletas);
  //     setLoading(false);
  //   }, 200);
  //   return () => clearTimeout(timer);
  // }, [search]);

  const toggleModal = (id) => {
    setModal(!modal);
    if (id) {
      setSelected(id);
    }
  };

  const handleSubmit = async () => {
    try {
      setModal(false);
      await api.patch(`administrador/coleta/${selected}/desabilitar`);
      setRefre(!refre);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  return (
    <Fragment>
      <Breadcrumb parent="Coletas" title="Lista de Coletas" />
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Confirmar</ModalHeader>
        <ModalBody>
          <p>
            A Coleta será apagado permanentemente do sistema. Deseja prosseguir?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleSubmit}>
            Ok
          </Button>
          <Button color="light" onClick={toggleModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      <Container fluid={true}>
        <Row style={{ marginBottom: "20px" }}>
          <Col xs="6">
            <InputGroup>
              <InputGroupText
                // className="border border-right-0"
                style={{ background: "transparent" }}
              >
                <Search size="1rem" />
              </InputGroupText>
              {/* <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              /> */}
              <Col xs="8">
              <Select
                options={companyFromAPI}
                onChange={(e) => setSearch(e.value)}
                noOptionsMessage={() => "Item não encontrado"}
                placeholder={"Empresa"}
                theme={(theme) => ({
                  ...theme,
                  
                  colors: {
                    ...theme.colors,
                    primary: "#80bdff",
                  },
                })}
                />
                </Col>



            </InputGroup>
          </Col>
          {/* <Col xs="6" sm="6" md="3" id={"btnCreate"} className="ml-auto">
            <Link
              to={`${process.env.PUBLIC_URL}/coletas/cadastrar`}
              className="btn btn-primary float-right disabled"
            >
              Cadastrar Coleta
            </Link>
            <UncontrolledTooltip placement="bottom" target={"btnCreate"}>
              Cadastrar coleta disponível na página da empresa
            </UncontrolledTooltip>
          </Col> */}
        </Row>
        {loading ? (
          <div className="d-flex justify-content-center">
            <ReactLoading type="balls" color="#5cad97" />
          </div>
        ) : (
          collects.map((el, i) => {
            return (
              <Card className="p-30" key={el.coleta_id}>
                <Row>
                  <Col xs="10" md="4" className="mb-3">
                    <b style={{ color: "#5cad97" }}>EMPRESA: </b>
                    {el.nome_fantasia}
                  </Col>
                  <Col xs="10" md="4" className="mb-3">
                    <b style={{ color: "#5cad97" }}>DATA: </b>
                    {el.data_coleta
                      .split("T")[0]
                      .split("-")
                      .reverse()
                      .join("/")}
                  </Col>
                  <Col xs="10" md="4" className="mb-3">
                    <b style={{ color: "#5cad97" }}>AVULSA: </b>
                    {el.is_avulsa ? "Sim" : "Não"}
                  </Col>
                </Row>

                <div
                  style={{
                    position: "absolute",
                    right: "1.5rem",
                    bottom: "1.5rem",
                  }}
                >
                  <Link
                    to={`${process.env.PUBLIC_URL}/coletas/visualizar/${el.coleta_id}`}
                  >
                    <Eye id={'visu'+i}/>
                    <UncontrolledTooltip
                      placement="top"
                      target={'visu'+i}
                    >
                      Detalhes
                    </UncontrolledTooltip>
                  </Link>

                  <Link
                    style={{ marginLeft: "1rem" }}
                    to={`${process.env.PUBLIC_URL}/coletas/atualizar/${el.coleta_id}`}
                  >
                    <Edit id={'edit'+i}/>
                    <UncontrolledTooltip
                      placement="top"
                      target={'edit'+i}
                    >
                      Editar
                    </UncontrolledTooltip>
                  </Link>
                  <Link
                    style={{ marginLeft: "1rem" }}
                    to={"#"}
                    onClick={() => toggleModal(el.coleta_id)}
                  >
                    <Trash2 id={'delete'+i}/>
                    <UncontrolledTooltip
                      placement="top"
                      target={'delete'+i}
                    >
                      Excluir
                    </UncontrolledTooltip>
                  </Link>
                </div>
              </Card>
            );
          })
        )}

        {search.length <= 0 && !loading && (
          <Card>
            <div className="mx-auto">
              {pgCount !== 1 && (
                <Pagination
                  className="m-30"
                  aria-label="Page navigation example"
                >
                  <ul className="pagination pagination-primary">
                    <PaginationItem disabled={page === 1}>
                      <PaginationLink
                        onClick={() => setPage(handlePrevPageChange(page))}
                      >
                        {Previous}
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem active={page === 1}>
                      <PaginationLink
                        onClick={() => setPage(leftButtonClick(page, pgCount))}
                      >
                        {leftButtonText(page, pgCount)}
                      </PaginationLink>
                    </PaginationItem>

                    <PaginationItem active={middleButtonActive(page, pgCount)}>
                      <PaginationLink
                        onClick={() =>
                          setPage(middleButtonClick(page, pgCount))
                        }
                      >
                        {middleButtonText(page, pgCount)}
                      </PaginationLink>
                    </PaginationItem>
                    {pgCount !== 2 && (
                      <PaginationItem active={page === pgCount}>
                        <PaginationLink
                          onClick={() =>
                            setPage(rightButtonClick(page, pgCount))
                          }
                        >
                          {rightButtonText(page, pgCount)}
                        </PaginationLink>
                      </PaginationItem>
                    )}
                    <PaginationItem disabled={page === pgCount}>
                      <PaginationLink onClick={() => setPage(page + 1)}>
                        {Next}
                      </PaginationLink>
                    </PaginationItem>
                  </ul>
                </Pagination>
              )}
            </div>
          </Card>
        )}
      </Container>
    </Fragment>
  );
};

export default List;
