import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  Col,
  Card,
  InputGroup,
  InputGroupText,
  Input,
  UncontrolledTooltip,
  ModalFooter,
  Button,
  ModalBody,
  ModalHeader,
  Modal,
} from "reactstrap";
import { Link } from "react-router-dom";
import { Next, Previous } from "../../constant";
import { Edit, Eye, Search, PlusCircle, Trash2 } from "react-feather";
import {
  leftButtonText,
  middleButtonText,
  rightButtonText,
  leftButtonClick,
  middleButtonClick,
  rightButtonClick,
  middleButtonActive,
  handlePrevPageChange,
} from "../../utils/pagination";
import { toast } from "react-toastify";
import { api } from "../../api/axios";
import ReactLoading from "react-loading";

const List = (props) => {
  const [modal, setModal] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [selected, setSelected] = useState("");
  const [refre, setRefre] = useState(false);
  const [page, setPage] = useState(1);
  const [pgCount, setPgCount] = useState(0);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const func = async () => {
      setLoading(true);
      const contracts = await api.get(
        `administrador/contratos?page=${page}&enabled=true`
      );
      setContracts(contracts.data.contratos);
      setPgCount(contracts.data.pagination.total_pages);
      setLoading(false);
    };
    func();
  }, [refre, page]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      setLoading(true);
      const res = await api.get(
        `administrador/contratos?page=0&enable=true&query=${search}`
      );
      setContracts(res.data.contratos);
      setLoading(false);
    }, 200);
    return () => clearTimeout(timer);
  }, [search]);

  const toggleModal = (id, nome) => {
    setModal(!modal);
    if (id) {
      setSelected({ id: id, nome: nome });
    }
  };

  const handleSubmit = async () => {
    try {
      setModal(false);
      await api.patch(`administrador/contrato/${selected.id}/desabilitar`);
      setRefre(!refre);
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Opps... Algo deu errado");
      }
      console.log(err.response);
    }
  };

  return (
    <Fragment>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Confirmar</ModalHeader>
        <ModalBody>
          <p>
            Um contrato da empresa
            <b style={{ color: "#5cad97" }}> {selected.nome}</b> será apagado
            permanentemente do sistema. Deseja prosseguir?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleSubmit}>
            Ok
          </Button>
          <Button color="light" onClick={toggleModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      <Breadcrumb parent="Contratos" title="Lista de Contratos" />
      <Container fluid={true}>
        <Row style={{ marginBottom: "20px" }}>
          <Col xs="6">
            <InputGroup>
              <InputGroupText
                className="border border-right-0"
                style={{ background: "transparent" }}
              >
                <Search size="1rem" />
              </InputGroupText>
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputGroup>
          </Col>
          {/* <Col xs="6" sm="6" md="3" id={"btnCreate"} className="ml-auto">
            <Link
              to={`${process.env.PUBLIC_URL}/contratos/cadastrar`}
              className="btn btn-primary float-right disabled w-100"
            >
              Cadastrar Contrato
            </Link>
            <UncontrolledTooltip placement="bottom" target={"btnCreate"}>
              Cadastrar contrato disponível na página da empresa
            </UncontrolledTooltip>
          </Col> */}
        </Row>
        {loading ? (
          <div className="d-flex justify-content-center">
            <ReactLoading type="balls" color="#5cad97" />
          </div>
        ) : (
          contracts.map((el, i) => {
            return (
              <Card className="p-30" key={el.contrato_id}>
                <Row>
                  <Col xs="10" md="5" className="mb-3">
                    <b style={{ color: "#5cad97" }}>EMPRESA: </b>
                    {el.nome_fantasia}
                  </Col>
                  <Col xs="10" md="5" className="mb-3">
                    <b style={{ color: "#5cad97" }}>DATA FIM: </b>
                    {el.data_fim.split("T")[0].split("-").reverse().join("/")}
                  </Col>
                </Row>
                <Row>
                  <Col xs="10" md="5" className="mb-3">
                    <b style={{ color: "#5cad97" }}>STATUS: </b>
                    {el.status === 1
                      ? "Ativo"
                      : el.status === 2
                      ? "Vencido"
                      : "Cancelado"}
                  </Col>
                  <Col xs="10" md="5" className="mb-3">
                    <b style={{ color: "#5cad97" }}>TIPO COBRANÇA: </b>
                    {el.tipocontrato === 1 ? "Kilos" : "Tambores"}
                  </Col>
                </Row>

                <div
                  style={{
                    position: "absolute",
                    right: "1.5rem",
                    bottom: "1.5rem",
                  }}
                >
                  <Link
                    to={`${process.env.PUBLIC_URL}/contratos/cadastrar/${el.contrato_id}`}
                  >
                    <PlusCircle id={"adicionar" + i} />
                    <UncontrolledTooltip
                      placement="top"
                      target={"adicionar" + i}
                    >
                      Contrato Aditivo
                    </UncontrolledTooltip>
                  </Link>
                  <Link
                    style={{ marginLeft: "1rem" }}
                    to={`${process.env.PUBLIC_URL}/contratos/visualizar/${el.contrato_id}`}
                  >
                    <Eye id={"detalhes" + i} />
                    <UncontrolledTooltip
                      placement="top"
                      target={"detalhes" + i}
                    >
                      Detalhes
                    </UncontrolledTooltip>
                  </Link>

                  <Link
                    style={{ marginLeft: "1rem" }}
                    to={`${process.env.PUBLIC_URL}/contratos/atualizar/${el.contrato_id}`}
                  >
                    <Edit id={"editar" + i} />
                    <UncontrolledTooltip placement="top" target={"editar" + i}>
                      Editar
                    </UncontrolledTooltip>
                  </Link>
                  {/* <Link style={{ marginLeft: "1rem" }} to={"#"}>
                    <Download id={"baixar" + i} />
                    <UncontrolledTooltip placement="top" target={"baixar" + i}>
                      Baixar Contrato
                    </UncontrolledTooltip>
                  </Link> */}
                  <Link
                    style={{ marginLeft: "1rem" }}
                    to={"#"}
                    onClick={() =>
                      toggleModal(el.contrato_id, el.nome_fantasia)
                    }
                  >
                    <Trash2 id={"excluir" + i} />
                    <UncontrolledTooltip placement="top" target={"excluir" + i}>
                      Excluir
                    </UncontrolledTooltip>
                  </Link>
                </div>
              </Card>
            );
          })
        )}

        {search.length <= 0 && !loading && (
          <Card>
            <div className="mx-auto">
              {pgCount !== 1 && (
                <Pagination
                  className="m-30"
                  aria-label="Page navigation example"
                >
                  <ul className="pagination pagination-primary">
                    <PaginationItem disabled={page === 1}>
                      <PaginationLink
                        onClick={() => setPage(handlePrevPageChange(page))}
                      >
                        {Previous}
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem active={page === 1}>
                      <PaginationLink
                        onClick={() => setPage(leftButtonClick(page, pgCount))}
                      >
                        {leftButtonText(page, pgCount)}
                      </PaginationLink>
                    </PaginationItem>

                    <PaginationItem active={middleButtonActive(page, pgCount)}>
                      <PaginationLink
                        onClick={() =>
                          setPage(middleButtonClick(page, pgCount))
                        }
                      >
                        {middleButtonText(page, pgCount)}
                      </PaginationLink>
                    </PaginationItem>
                    {pgCount !== 2 && (
                      <PaginationItem active={page === pgCount}>
                        <PaginationLink
                          onClick={() =>
                            setPage(rightButtonClick(page, pgCount))
                          }
                        >
                          {rightButtonText(page, pgCount)}
                        </PaginationLink>
                      </PaginationItem>
                    )}
                    <PaginationItem disabled={page === pgCount}>
                      <PaginationLink onClick={() => setPage(page + 1)}>
                        {Next}
                      </PaginationLink>
                    </PaginationItem>
                  </ul>
                </Pagination>
              )}
            </div>
          </Card>
        )}
      </Container>
    </Fragment>
  );
};

export default List;
