import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import Breadcrumb from "../../layout/breadcrumb";
import { Card, Col, Container, Row } from "reactstrap";
import { api } from "../../api/axios";

const View = (props) => {
  const { id } = useParams();
  const [driver, setDriver] = useState({});

  useEffect(() => {
    const func = async () => {
      const res = await api.get(`administrador/motorista/${id}`);
      setDriver(res.data.motorista);
    };
    if (id) func();
  }, [id]);

  return (
    <>
      <Breadcrumb parent="Motoristas" title="Detalhes do Motorista" />
      <Container fluid={true}>
        <Card className="p-30" style={{ borderRadius: "0px 15px 15px 15px" }}>
          <Row>
            <Col>
              <p>
                <b style={{ color: "#5cad97" }}>Nome: </b> {driver.nome}
              </p>
              <p>
                <b style={{ color: "#5cad97" }}>Email: </b>
                {driver.email}
              </p>
            </Col>
            <Col>
              <p>
                <b style={{ color: "#5cad97" }}>Telefone: </b> {driver.telefone}
              </p>
              <p>
                <b style={{ color: "#5cad97" }}>Cpf: </b>
                {driver.cpf}
              </p>
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  );
};

export default View;
