import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import Breadcrumb from "../../layout/breadcrumb";
import { Card, Col, Container, Row } from "reactstrap";
import { api } from "../../api/axios";

const View = (props) => {
  const { id } = useParams();
  const [barrels, setBarrels] = useState([]);
  const [address, setAddress] = useState({});
  const [daysWeek, setDaysWeek] = useState([]);
  const [contract, setContract] = useState({});

  useEffect(() => {
    const func = async () => {
      const res = await api.get(`administrador/contrato/${id}`);
      const json = await res.data;
      setAddress(json.enderecoColeta);
      setBarrels(json.tambores);
      setDaysWeek(json.diaSemana);
      setContract(json);
    };
    if (id) func();
  }, [id]);

  return (
    <>
      <Breadcrumb parent="Contratos" title="Visualizar Contrato" />
      <Container fluid={true}>
        <Card className="p-30" style={{ borderRadius: "0px 15px 15px 15px" }}>
          <h5>Detalhes</h5>
          <Row>
            <Col>
              <p>
                <b style={{ color: "#5cad97" }}>Tipo do Contrato: </b>
                {contract.tipoContrato === 0 ? "Contrato" : "Aditivo"}
              </p>
              <p>
                <b style={{ color: "#5cad97" }}>Tipo da Cobrança: </b>
                {contract.tipoCobranca === 0 ? "Kilo" : "Tambor"}
              </p>
              <p>
                <b style={{ color: "#5cad97" }}>Data início: </b>
                {contract.dataInicio}
              </p>
            </Col>
            <Col>
              <p>
                <b style={{ color: "#5cad97" }}>Valor Excedente: </b>
                {contract.valorExcedenteFormatado}
              </p>
              <p>
                <b style={{ color: "#5cad97" }}>
                  Quantidade Minima de Coleta:{" "}
                </b>
                {contract.qtMinimaColeta}
              </p>
              <p>
                <b style={{ color: "#5cad97" }}>Data Fim:</b> {contract.dataFim}
              </p>
            </Col>
            <Col>
              <p>
                <b style={{ color: "#5cad97" }}>Valor Minimo Mensal: </b>
                {contract.valorMinimoMensalFormatado}
              </p>
              <p>
                <b style={{ color: "#5cad97" }}>Valor Minimo Anual: </b>
                {contract.valorMinimoAnualFormatado}
              </p>
              <p>
                <b style={{ color: "#5cad97" }}>Dia Vencimento: </b>
                {contract.diaVencimento}
              </p>
            </Col>
          </Row>
          <h5>Endereco</h5>
          <Row>
            <Col>
              <p>
                <b style={{ color: "#5cad97" }}>Rua:</b> {address.logradouro}
              </p>
              <p>
                <b style={{ color: "#5cad97" }}>Bairro:</b> {address.bairro}
              </p>
              <p>
                <b style={{ color: "#5cad97" }}>Estado: </b> {address.estado}
              </p>
            </Col>
            <Col>
              <p>
                <b style={{ color: "#5cad97" }}>Cidade: </b> {address.cidade}
              </p>
              <p>
                <b style={{ color: "#5cad97" }}>CEP: </b> {address.cep}
              </p>
              <p>
                <b style={{ color: "#5cad97" }}>Numero: </b> {address.numero}
              </p>
            </Col>
          </Row>
          {daysWeek.length > 0 && (
            <>
              <h5>Dias Coleta</h5>
              <Row>
                <Col>
                  <p>
                    {daysWeek.map((e) => {
                      return (
                        <b
                          key={e.nome}
                          style={{ marginRight: "1rem", color: "#5cad97" }}
                        >
                          {e.nome}
                        </b>
                      );
                    })}
                  </p>
                </Col>
              </Row>
            </>
          )}

          {barrels.length > 0 && (
            <>
              <h5>Tambores</h5>
              {barrels.map((e) => {
                return (
                  <Row key={e.tambor_id}>
                    <Col xs="3">
                      <b style={{ color: "#5cad97" }}>Nome: </b>
                      {e.nome}
                    </Col>
                    <Col xs="3">
                      <b style={{ color: "#5cad97" }}>Valor: </b>
                      {e.valorCobrancaFormatado}
                    </Col>
                    <Col xs="6">
                      <b style={{ color: "#5cad97" }}>Descrição: </b>
                      {e.descricao}
                    </Col>
                  </Row>
                );
              })}
            </>
          )}
        </Card>
      </Container>
    </>
  );
};

export default View;
