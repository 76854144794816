export const leftButtonText = (page, pgCount) => {
  if (pgCount === 2 || page === 1) return 1;

  if (page === pgCount) {
    return page - 2;
  }
  return page - 1;
};
export const middleButtonText = (page, pgCount) => {
  if (pgCount === 2 || page === 1) return 2;

  if (page === pgCount) {
    return page - 1;
  }
  return page;
};
export const rightButtonText = (page, pgCount) => {
  if (pgCount !== 2 && page === 1) {
    return page + 2;
  }
  if (pgCount === page) {
    return page;
  }

  if (page !== pgCount) {
    return page + 1;
  }
};
export const leftButtonClick = (page, pgCount) => {
  if (pgCount === 2) return 1;
  if (pgCount === page) return page - 2;
  if (page === 1) return page;
  return page - 1;
};
export const middleButtonClick = (page, pgCount) => {
  if (pgCount === 2 || page === 1) {
    return 2;
  }
  if (pgCount === page) {
    return page - 1;
  } else {
    return page;
  }
};
export const rightButtonClick = (page, pgCount) => {
  if (page === 1 && pgCount !== 2) {
    return page + 2;
  }
  if (page !== pgCount) {
    return page + 1;
  }
  return page;
};

export const middleButtonActive = (page, pgCount) => {
  if (page === 2 && pgCount === 2) {
    return true;
  }
  if (page === 1 || page === pgCount) {
    return false;
  }
  return true;
};

export const handlePrevPageChange = (page) => {
  if (page <= 0) {
    return 0;
  } else {
    return page - 1;
  }
};
