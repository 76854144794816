import Axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const jwt_token = localStorage.getItem("token");

export const api = Axios.create({
  baseURL: baseUrl,
  headers: {
    Authorization: `Bearer ${jwt_token}`,
    "Content-Type": "application/json",
  },
});

export const publicApi = Axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

export const downloadPDF = Axios.create({
  baseURL: baseUrl,
  headers: {
    Authorization: `Bearer ${jwt_token}`,
  },
  responseType: "blob",
});
