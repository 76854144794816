import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { CreateAccount } from "../../constant";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { api } from "../../api/axios";
import Select from "react-select";

const Edit = (props) => {
  const [ok, setOk] = useState(false);
  const [placa, setPlaca] = useState("");
  const [color, setColor] = useState("");
  const [models, setModels] = useState([]);
  const [model, setModel] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const checkError = () => {
    let err = {};
    err["placa"] = placa.length < 5;
    err["model"] = model === "";
    err["color"] = color.length < 3;
    setErrors(err);
    return Object.values(err).filter((e) => {
      return e;
    }).length;
  };

  const { id } = useParams();
  useEffect(() => {
    const func = async () => {
      const res = await api.get(`administrador/veiculos/${id}`);
      const json = await res.data;
      setPlaca(json[0].placa);
      setModel(json[0].modelo_id);
      setColor(json[0].cor);
    };
    if (id) func();
  }, [id]);

  useEffect(() => {
    const func = async () => {
      const models = await api.get(`administrador/modelo/modelos`);
      setModels(
        models.data.modelos.map((el) => {
          return { value: el.id_modelo, label: el.modelo };
        })
      );
    };
    func();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (checkError() > 0) return;
    setLoading(true);

    try {
      await api.patch(`administrador/veiculos/${id}/atualizar`, {
        placa,
        modelo: Number(model),
        cor: color,
      });
      setOk(true);
    } catch (err) {
      setLoading(false);
      if (err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Ops... Algo deu errado");
      }
    }
  };

  return (
    <Fragment>
      {ok && <Redirect to={`${process.env.PUBLIC_URL}/veiculos/listar`} />}
      <Breadcrumb parent="Veículos" title="Atualizar Veículo" />
      <Container fluid={true}>
        <Row>
          <Col xs="12">
            <div className="card">
              <Form className="card-body" onSubmit={(e) => handleSubmit(e)}>
                <FormGroup>
                  <Label className="col-form-label">Placa</Label>
                  <Input
                    className={
                      errors["placa"]
                        ? "form-control border-danger"
                        : "form-control"
                    }
                    type="text"
                    required=""
                    placeholder="XXXXXXXX"
                    value={placa}
                    onChange={(e) => setPlaca(e.target.value)}
                  />
                  {errors["placa"] && (
                    <p className="text-danger">Placa inválida</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">Modelo</Label>
                  <Select
                    value={models.filter((e) => {
                      return e.value === Number(model);
                    })}
                    options={models}
                    onChange={(e) => setModel(e.value)}
                    noOptionsMessage={() => "Item não encontrado"}
                    placeholder={"Modelo"}
                    theme={(theme) => ({
                      ...theme,

                      colors: {
                        ...theme.colors,
                        primary: "#80bdff",
                      },
                    })}
                  />
                  {errors["model"] && (
                    <p className="text-danger">Modelo inválido</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">Cor</Label>
                  <Input
                    className={
                      errors["color"]
                        ? "form-control border-danger"
                        : "form-control"
                    }
                    type="text"
                    required=""
                    placeholder="preto"
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                  />
                  {errors["color"] && (
                    <p className="text-danger">Cor inválida</p>
                  )}
                </FormGroup>
                <div className="form-group mb-0">
                  <Button
                    color="primary"
                    className="btn-block"
                    type="submit"
                    disabled={loading}
                  >
                    {CreateAccount}
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Edit;
