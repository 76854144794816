import React, { Fragment, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { CreateAccount } from "../../constant";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { numberMask } from "../../utils/masks";
import { api } from "../../api/axios";

const Create = (props) => {
  const [ok, setOk] = useState(false);
  const [name, setName] = useState("");
  const [capacity, setCapacity] = useState("");
  const [litre, setLitre] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const checkError = () => {
    let err = {};
    err.name = name.length < 5;
    err.capacity = capacity.length < 1;
    err.litre = litre.length < 1;
    err.description = description.length < 10;
    setErrors(err);
    return Object.values(err).filter((e) => {
      return e;
    }).length;
  };

  const handleCreateBarrel = async (e) => {
    e.preventDefault();
    if (checkError() > 0) return;
    setLoading(true);

    try {
      await api.post(`administrador/tambores/cadastrar`, {
        nome: name,
        capacidade: Number(capacity.replace(".", "").replace(",", ".")),
        litros: Number(litre.replace(".", "").replace(",", ".")),
        descricao: description,
      });
      setOk(true);
    } catch (err) {
      setLoading(false);
      if (err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Ops... Algo deu errado");
      }
    }
  };

  return (
    <Fragment>
      {ok && <Redirect to={`${process.env.PUBLIC_URL}/tambores/listar`} />}
      <Breadcrumb parent="Tambores" title="Cadastrar Tambor" />
      <Container fluid={true}>
        <Row>
          <Col xs="12">
            <div className="card">
              <Form
                className="card-body"
                onSubmit={(e) => handleCreateBarrel(e)}
              >
                <div className="form-row">
                  <Col xs="4">
                    <FormGroup>
                      <Label className="col-form-label">Nome</Label>
                      <Input
                        className={
                          errors["name"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="Tambor c-500"
                        value={name}
                        onChange={(e) =>
                          setName(
                            e.target.value.charAt(0).toUpperCase() +
                              e.target.value.slice(1)
                          )
                        }
                      />
                      {errors["name"] && (
                        <p className="text-danger">Nome inválido</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="4">
                    <FormGroup>
                      <Label className="col-form-label">Capacidade</Label>
                      <Input
                        className={
                          errors["capacity"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="000,00"
                        value={capacity}
                        onChange={(e) =>
                          setCapacity(numberMask(e.target.value))
                        }
                      />
                      {errors["capacity"] && (
                        <p className="text-danger">Capacidade inválida</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="4">
                    <FormGroup>
                      <Label className="col-form-label">Litros</Label>
                      <Input
                        className={
                          errors["litre"]
                            ? "form-control border-danger"
                            : "form-control"
                        }
                        type="text"
                        required=""
                        placeholder="000,00"
                        value={litre}
                        onChange={(e) => setLitre(numberMask(e.target.value))}
                      />
                      {errors["litre"] && (
                        <p className="text-danger">Valor inválido</p>
                      )}
                    </FormGroup>
                  </Col>
                </div>

                <FormGroup>
                  <Label className="col-form-label">Descrição</Label>
                  <Input
                    className={
                      errors["description"]
                        ? "form-control border-danger"
                        : "form-control"
                    }
                    type="textarea"
                    required=""
                    placeholder="Uma breve descrição"
                    value={description}
                    onChange={(e) =>
                      setDescription(
                        e.target.value.charAt(0).toUpperCase() +
                          e.target.value.slice(1)
                      )
                    }
                  />
                  {errors["description"] && (
                    <p className="text-danger">Descrição inválida</p>
                  )}
                </FormGroup>
                <div className="form-group mb-0">
                  <Button
                    color="primary"
                    className="btn-block"
                    type="submit"
                    disabled={loading}
                  >
                    {CreateAccount}
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Create;
