import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";
import { dateMaskMY, numberMask } from "../../utils/masks";
import Select from "react-select";
import { api } from "../../api/axios";

const Create = (props) => {
  const [okCalc, setOkCalc] = useState(false);
  const [okCreate, setOkCreate] = useState(false);

  const [company, setCompany] = useState("");
  const [companyFromAPI, setCompanyFromAPI] = useState([]);
  const [date, setdate] = useState("");
  const [value, setValue] = useState(0);
  const [errors, setErrors] = useState({});
  const [res, setRes] = useState({});
  const [loading, setLoading] = useState(false);

  const checkError = () => {
    let err = {};
    err["empresa"] = company === "";
    err["date"] = date.length < 5 || date.length === 6;
    err["valor"] = okCalc && value.length < 1;
    setErrors(err);
    return Object.values(err).filter((e) => {
      return e;
    }).length;
  };

  useEffect(() => {
    const func = async () => {
      const res = await api.get(`administrador/cobranca/empresas`);
      setCompanyFromAPI(
        res.data.empresas.map((el) => {
          return { value: el.id_empresa, label: el.nome_fantasia };
        })
      );
    };
    func();
  }, []);

  const handleCalcCharge = async (e) => {
    e.preventDefault();
    if (checkError() > 0) return;
    setLoading(true);

    try {
      let res = await api.post(`administrador/cobranca/calcular`, {
        empresa_id: company,
        mes_ref: date.split("/").reverse().join("-"),
      });
      console.log(res);
      setRes(res.data);

      setValue(numberMask(res.data.valor.toFixed(2)));
      setOkCalc(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Ops... Algo deu errado");
      }
    }
  };
  const handleCreateCharge = async (e) => {
    e.preventDefault();
    if (checkError() > 0) return;
    setLoading(true);
    try {
      await api.post(`administrador/cobranca/gerarCobranca`, {
        empresa_id: company,
        mes_ref: date.split("/").reverse().join("-"),
        valor: Number(value.replace(".", "").replace(",", ".")),
      });
      setOkCreate(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Ops... Algo deu errado");
      }
    }
  };

  return (
    <Fragment>
      <Breadcrumb parent="Coletas" title="Gerar Cobrança" />
      <Container fluid={true}>
        <Row>
          <Col xs="12">
            <div className="card">
              {okCreate ? (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    // setdate("");
                    setOkCalc(false);
                    setOkCreate(false);
                  }}
                >
                  <div className="form-row">
                    <Col xs="6">
                      <h3>Cobranca Gerada com sucesso</h3>
                    </Col>
                  </div>
                  <div className="form-group mb-0">
                    <Button color="primary" className="btn-block" type="submit">
                      Nova Cobranca
                    </Button>
                  </div>
                </Form>
              ) : !okCalc ? (
                <Form
                  className="card-body"
                  onSubmit={(e) => handleCalcCharge(e)}
                >
                  <div className="form-row">
                    <Col xs="6">
                      <FormGroup>
                        <Label className="col-form-label">Empresa</Label>
                        <Select
                          options={companyFromAPI}
                          onChange={(e) => setCompany(e.value)}
                          noOptionsMessage={() => "Item não encontrado"}
                          placeholder={"Empresa"}
                          theme={(theme) => ({
                            ...theme,

                            colors: {
                              ...theme.colors,
                              primary: "#80bdff",
                            },
                          })}
                        />
                        {errors["driver"] && (
                          <p className="text-danger">Empresa Inválida </p>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <Label className="col-form-label">
                          Mês de referência
                        </Label>
                        <Input
                          className={
                            errors["date"]
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          type="text"
                          required=""
                          placeholder="00/000"
                          value={date}
                          onChange={(e) => setdate(dateMaskMY(e.target.value))}
                        />
                        {errors["date"] && (
                          <p className="text-danger">Data inválida</p>
                        )}
                      </FormGroup>
                    </Col>
                  </div>
                  <div className="form-group mb-0">
                    <Button
                      color="primary"
                      className="btn-block"
                      type="submit"
                      disabled={loading}
                    >
                      Calcular
                    </Button>
                  </div>
                </Form>
              ) : (
                <Form
                  className="card-body"
                  onSubmit={(e) => handleCreateCharge(e)}
                >
                  <div className="form-row">
                    <Col xs="6">
                      <h3>Empresa</h3>

                      <p>
                        <span style={{ color: "#5cad97", fontWeight: "bold" }}>
                          Nome:{" "}
                        </span>
                        {res.empresa.nome_fantasia}
                      </p>
                      <p>
                        <span style={{ color: "#5cad97", fontWeight: "bold" }}>
                          Email:{" "}
                        </span>
                        {res.empresa.email}
                      </p>
                      <p>
                        <span style={{ color: "#5cad97", fontWeight: "bold" }}>
                          cnpj/cpf:{" "}
                        </span>
                        {res.empresa.cnpj_cpf}
                      </p>

                      <h3>Contrato</h3>
                      <p>
                        <span style={{ color: "#5cad97", fontWeight: "bold" }}>
                          Aditivo:{" "}
                        </span>
                        {res.contrato.tipoContrato === 0 ? "Nao" : "Sim"}
                      </p>
                      <p>
                        <span style={{ color: "#5cad97", fontWeight: "bold" }}>
                          Data Fim:{" "}
                        </span>
                        {res.contrato.dataFim}
                      </p>
                      <p>
                        <span style={{ color: "#5cad97", fontWeight: "bold" }}>
                          Valor Excedente:{" "}
                        </span>
                        {res.contrato.valorExcedenteFormatado}
                      </p>
                      <p>
                        <span style={{ color: "#5cad97", fontWeight: "bold" }}>
                          Tipo:{" "}
                        </span>
                        {res.contrato.tipoCobranca === "0" ? "Tambor" : "Kilo"}
                      </p>
                      <p>
                        <span style={{ color: "#5cad97", fontWeight: "bold" }}>
                          Valor Minimo Mensal:{" "}
                        </span>
                        {res.contrato.valorMinimoMensalFormatado}
                      </p>
                      <h3>Tambores</h3>
                      {res.contrato.tambores.map((e, i) => {
                        return (
                          <p
                            key={`${e}:${i}`}
                          >{`${e.descricao} (${e.valorCobrancaFormatado})`}</p>
                        );
                      })}
                      <h3>Coleta</h3>
                      {res.coletas.map((e, i) => {
                        return (
                          <React.Fragment key={`${e}:${i}`}>
                            <p style={{ color: "#5cad97", fontWeight: "bold" }}>
                              Codigo: {e.codigo} Data: {e.data_coleta}
                            </p>
                            <ul style={{ marginBottom: "2rem" }}>
                              {e.tambores.map((e, i) => {
                                return (
                                  <li key={`${e}:${i}`}>{`- ${
                                    e.nome
                                  } (R$ ${e.valor_cobranca.toFixed(
                                    2
                                  )} - ${e.peso.toFixed(2)} Kg)`}</li>
                                );
                              })}
                            </ul>
                          </React.Fragment>
                        );
                      })}

                      <h3>Valor Sugerido</h3>
                      <FormGroup>
                        <Input
                          type="text"
                          value={value}
                          onChange={(e) => {
                            setValue(numberMask(e.target.value));
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </div>

                  <div className="form-group mb-0">
                    <Button
                      color="primary"
                      className="btn-block"
                      type="submit"
                      disabled={loading}
                    >
                      Gerar
                    </Button>
                  </div>
                </Form>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Create;
