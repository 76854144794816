import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import {
  Password,
  SignIn,
  EmailAddress,
  CreateAccount,
  YourName,
  PrivacyPolicy,
  Telephone,
  CPF,
  AdmToken,
} from "../../constant";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { cpfMask, phoneMask } from "../../utils/masks";
import { api } from "../../api/axios";

const Register = (props) => {
  const jwt_token = localStorage.getItem("token");

  const [registred, setRegistred] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [name, setName] = useState("");
  const [last, setLast] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [cpf, setCpf] = useState("");
  const [password, setPassword] = useState("");
  const [admToken, setAdmToken] = useState("");
  const [agree, setAgree] = useState(false);

  const checkError = () => {
    if (!/\S+@\S+\.\S+/.test(email) || email.length < 10)
      return "Email Inválido";
    if (name.length < 2 || last.length < 2) return "Nome Inválido!";
    if (phone.length < 16) return "Telefone Inválido!";
    if (cpf.length < 14) return "CPF Inválido!";
    if (password.length < 8) return "Senha Inválida!";
    if (admToken.length < 20) return "Token Inválido!";
    if (!agree) return "Concorde com os termos!";
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const error = checkError();
    if (error) {
      return toast.error(error);
    }

    try {
      if (agree) {
        await api.post(`publico/cadastrarAdmin`, {
          nome: `${name} ${last}`,
          email: email,
          cpf: cpf,
          telefone: phone.replace(".", ""),
          password: password,
        });
        setRegistred(true);
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const HideShowPassword = (tPassword) => {
    setTogglePassword(!tPassword);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleLastChange = (e) => {
    setLast(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePhoneChange = (e) => {
    setPhone(phoneMask(e.target.value));
  };
  const handleCpfChange = (e) => {
    setCpf(cpfMask(e.target.value));
  };
  const handleAdmTokenChange = (e) => {
    setAdmToken(e.target.value);
  };

  return (
    <Container fluid={true} className="p-0">
      {registred && (
        <Redirect to={`${process.env.PUBLIC_URL}/pages/auth/login`} />
      )}

      {jwt_token && <Redirect to={`${process.env.PUBLIC_URL}/`} />}
      {/* <h4>{error}</h4> */}
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div>
                <a className="logo" href="#javascript">
                  <img
                    style={{ maxWidth: "200px" }}
                    className="img-fluid for-light"
                    src={require("../../assets/images/logo/login.svg")}
                    alt="looginpage"
                  />
                  <img
                    style={{ maxWidth: "200px" }}
                    className="img-fluid for-dark"
                    src={require("../../assets/images/logo/logo_dark.svg")}
                    alt="looginpage"
                  />
                </a>
              </div>

              <div className="login-main">
                <Form className="theme-form" onSubmit={(e) => handleSubmit(e)}>
                  <h4>{"Criar sua conta"}</h4>
                  <p>{"Preencha os campos para criar a conta"}</p>
                  <FormGroup>
                    <Label className="col-form-label pt-0">{YourName}</Label>
                    <div className="form-row">
                      <Col xs="6">
                        <Input
                          className="form-control"
                          type="text"
                          required=""
                          placeholder="Nome"
                          value={name}
                          onChange={(e) => handleNameChange(e)}
                        />
                      </Col>
                      <Col xs="6">
                        <Input
                          className="form-control"
                          type="text"
                          required=""
                          placeholder="Sobrenome"
                          value={last}
                          onChange={(e) => handleLastChange(e)}
                        />
                      </Col>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{EmailAddress}</Label>
                    <Input
                      className="form-control"
                      type="text"
                      required=""
                      placeholder="seunome@gmail.com"
                      value={email}
                      onChange={(e) => handleEmailChange(e)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{Telephone}</Label>
                    <Input
                      className="form-control"
                      type="tel"
                      required=""
                      placeholder="(99) 9.9999-9999"
                      value={phone}
                      onChange={(e) => handlePhoneChange(e)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{CPF}</Label>
                    <Input
                      className="form-control"
                      type="tel"
                      required=""
                      placeholder="999.999.999-99"
                      value={cpf}
                      onChange={(e) => handleCpfChange(e)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{Password}</Label>
                    <Input
                      className="form-control"
                      type={togglePassword ? "text" : "password"}
                      name="login[password]"
                      value={password}
                      onChange={(e) => handlePasswordChange(e)}
                      required=""
                      placeholder="*********"
                    />
                    <div
                      className="show-hide"
                      onClick={() => HideShowPassword(togglePassword)}
                    >
                      <span className={togglePassword ? "" : "show"}></span>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{AdmToken}</Label>
                    <Input
                      className="form-control"
                      type="tel"
                      required=""
                      placeholder="MASTERXXXXXXXXXXXXXXXXXXXXXXX"
                      value={admToken}
                      onChange={(e) => handleAdmTokenChange(e)}
                    />
                  </FormGroup>
                  <div className="form-group mb-0">
                    <div className="checkbox ml-3">
                      <Input
                        id="checkbox1"
                        type="checkbox"
                        checked={agree}
                        onChange={({ target }) => setAgree(target.checked)}
                      />
                      <Label className="text-muted" for="checkbox1">
                        {"Concordo com os"}
                        <a className="ml-2" href="#javascript">
                          {PrivacyPolicy}
                        </a>
                      </Label>
                    </div>
                    <Button color="primary" className="btn-block" type="submit">
                      {CreateAccount}
                    </Button>
                  </div>
                  <p className="mt-4 mb-0">
                    {"Já possui uma conta?"}
                    <a
                      className="ml-2"
                      href={`${process.env.PUBLIC_URL}/pages/auth/login`}
                    >
                      {SignIn}
                    </a>
                  </p>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Register;
