import React, { Fragment, useState, useEffect } from "react";
import man from "../../assets/images/dashboard/profile.jpg";
import {
  LogIn,
  Minimize,
  Search,
  /*
  Moon,
  Sun,
  CreditCard,
  List,
  */
} from "react-feather";
import { useHistory } from "react-router-dom";
import { Admin, LogOut } from "../../constant";

const Rightbar = (props) => {
  const history = useHistory();
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const [searchresponsive, setSearchresponsive] = useState(false);
  // const [moonlight, setMoonlight] = useState(false);
  // const [card, setCard] = useState(false);

  useEffect(() => {
    setProfile(localStorage.getItem("profileURL") || man);
    setName(localStorage.getItem("Name"));
    /*
    if (localStorage.getItem("theme") === "dark") {
      document.body.className = "dark-only";
      setMoonlight(true);
    }
    if (localStorage.getItem("lista") === "card") {
      setCard(true);
    }
    */
  }, []);
  /*
  const handleCardToggle = (card) => {
    if (card) {
      localStorage.setItem("lista", "table");
      setCard(false);
    } else {
      localStorage.setItem("lista", "card");
      setCard(true);
    }
    history.go(0);
  };
  */
  /*
  const MoonlightToggle = (light) => {
    if (light) {
      document.body.className = "light";
      localStorage.setItem("theme", "light");
      setMoonlight(!light);
    } else {
      document.body.className = "dark-only";
      localStorage.setItem("theme", "dark");
      setMoonlight(!light);
    }
  };
*/
  const logout = () => {
    // localStorage.removeItem('token');
    localStorage.clear();
    history.push(`${process.env.PUBLIC_URL}/pages/auth/login`);
    window.location.href = `${process.env.PUBLIC_URL}/pages/auth/login`;
  };

  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const SeacrhResposive = (searchresponsive) => {
    if (searchresponsive) {
      setSearchresponsive(!searchresponsive);
      document.querySelector(".search-full").classList.add("open");
    } else {
      setSearchresponsive(!searchresponsive);
      document.querySelector(".search-full").classList.remove("open");
    }
  };
  return (
    <Fragment>
      <div className="nav-right pull-right right-header p-0">
        <ul className="nav-menus">
          <li>
            <span className="header-search">
              <Search onClick={() => SeacrhResposive(searchresponsive)} />
            </span>
          </li>
          <li className="maximize">
            <a className="text-dark" href="#javascript" onClick={goFull}>
              <Minimize />
            </a>
          </li>
          <li className="profile-nav onhover-dropdown p-0">
            <div className="media profile-media">
              <img className="b-r-10" src={profile} alt="" />
              <div className="media-body">
                <span>{name}</span>
                <p className="mb-0 font-roboto">
                  {Admin} <i className="middle fa fa-angle-down"></i>
                </p>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
              {/* <li onClick={() => handleCardToggle(card)}>
                {card ? (
                  <>
                    <CreditCard />
                    <span> Cards </span>
                  </>
                ) : (
                  <>
                    <List />
                    <span> Tabela </span>
                  </>
                )}
              </li>
              <li onClick={() => MoonlightToggle(moonlight)}>
                {moonlight ? (
                  <>
                    <Moon />
                    <span> Modo Escuro </span>
                  </>
                ) : (
                  <>
                    <Sun />
                    <span> Modo Claro </span>
                  </>
                )}
              </li> */}
              <li onClick={logout}>
                <LogIn />
                <span>{LogOut}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};
export default Rightbar;
