import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./components/app";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { routes } from "./route";

import Home from "./pages/home/Home";
import Login from "./pages/authentication/login";
import Register from "./pages/authentication/register";
import Error400 from "./pages/errors/error400";
import Error401 from "./pages/errors/error401";
import Error403 from "./pages/errors/error403";
import Error404 from "./pages/errors/error404";
import Error500 from "./pages/errors/error500";
import Error503 from "./pages/errors/error503";

import Maintenance from "./pages/maintenance";

const Root = (props) => {
  const jwt_token = localStorage.getItem("token");
  return (
    <Fragment>
      <BrowserRouter basename={`/`}>
        <Switch>
          <Route
            path={`${process.env.PUBLIC_URL}/pages/auth/login`}
            component={Login}
          ></Route>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            component={Home}
          ></Route>
          <Route
            path={`${process.env.PUBLIC_URL}/pages/auth/signup`}
            component={Register}
          ></Route>

          <Route
            path={`${process.env.PUBLIC_URL}/pages/errors/error400`}
            component={Error400}
          ></Route>
          <Route
            path={`${process.env.PUBLIC_URL}/pages/errors/error401`}
            component={Error401}
          ></Route>
          <Route
            path={`${process.env.PUBLIC_URL}/pages/errors/error403`}
            component={Error403}
          ></Route>
          <Route
            path={`${process.env.PUBLIC_URL}/pages/errors/error404`}
            component={Error404}
          ></Route>
          <Route
            path={`${process.env.PUBLIC_URL}/pages/errors/error500`}
            component={Error500}
          ></Route>
          <Route
            path={`${process.env.PUBLIC_URL}/pages/errors/error503`}
            component={Error503}
          ></Route>
          <Route
            path={`${process.env.PUBLIC_URL}/pages/maintenance`}
            component={Maintenance}
          ></Route>

          {jwt_token ? (
            <App>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/`}
                render={() => {
                  return (
                    <Redirect
                      to={`${process.env.PUBLIC_URL}/home`}
                    />
                  );
                }}
              />
              <TransitionGroup>
                {routes.map(({ path, Component }) => (
                  <Route
                    key={path}
                    exact
                    path={`${process.env.PUBLIC_URL}${path}`}
                  >
                    {({ match }) => (
                      <CSSTransition
                        in={match != null}
                        timeout={100}
                        classNames="fade"
                        unmountOnExit
                      >
                        <div>
                          <Component />
                        </div>
                      </CSSTransition>
                    )}
                  </Route>
                ))}
              </TransitionGroup>
            </App>
          ) : (
            <Redirect to={`${process.env.PUBLIC_URL}/pages/auth/login`} />
          )}
        </Switch>
      </BrowserRouter>
    </Fragment>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.unregister();
