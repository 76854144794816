import Default from "../components/dashboard/default";

import CreateAdmin from "../pages/admin/create";
import ListAdmin from "../pages/admin/listCard";
import EditAdmin from "../pages/admin/edit";
import ViewAdmin from "../pages/admin/view";

// import CreateClient from "../pages/client/create";
// import ListClient from "../pages/client/listCard";
// import EditClient from "../pages/client/edit";

import CreateVehicle from "../pages/vehicle/create";
import EditVehicle from "../pages/vehicle/edit";
import ListVehicle from "../pages/vehicle/listCard";
import ViewVehicle from "../pages/vehicle/view";

import CreateBarrel from "../pages/barrel/create";
import EditBarrel from "../pages/barrel/edit";
import ListBarrel from "../pages/barrel/listCard";
import ViewBarrel from "../pages/barrel/view";

import CreateCompany from "../pages/company/create";
import EditCompany from "../pages/company/edit";
import ListCompany from "../pages/company/listCard";
import ViewCompany from "../pages/company/view";

import CreateContract from "../pages/contract/create";
import EditContract from "../pages/contract/edit";
import ListContract from "../pages/contract/listCard";
import ViewContract from "../pages/contract/view";

import ListDriver from "../pages/driver/listCard";
import CreateDriver from "../pages/driver/create";
import EditDriver from "../pages/driver/edit";
import ViewDriver from "../pages/driver/view";

import CreateCollect from "../pages/collect/create";
import EditCollect from "../pages/collect/edit";
import ListCollect from "../pages/collect/listCard";
import ViewCollect from "../pages/collect/view";

import CreateCharge from "../pages/charge/create";
import ListCharge from "../pages/charge/listCard";
import ExtractCharge from "../pages/charge/extractCharge";

export const routes = [
  // admin
  { path: "/administradores/cadastrar", Component: CreateAdmin },
  { path: "/administradores/listar", Component: ListAdmin },
  { path: "/administradores/atualizar/:id", Component: EditAdmin },
  { path: "/administradores/visualizar/:id", Component: ViewAdmin },
  // clients
  // { path: "/clientes/cadastrar", Component: CreateClient },
  // { path: "/clientes/listar", Component: ListClient },
  // { path: "/clientes/atualizar/:id", Component: EditClient },
  // vehicles
  { path: "/veiculos/cadastrar", Component: CreateVehicle },
  { path: "/veiculos/listar", Component: ListVehicle },
  { path: "/veiculos/atualizar/:id", Component: EditVehicle },
  { path: "/veiculos/visualizar/:id", Component: ViewVehicle },
  // barrels
  { path: "/tambores/cadastrar", Component: CreateBarrel },
  { path: "/tambores/listar", Component: ListBarrel },
  { path: "/tambores/atualizar/:id", Component: EditBarrel },
  { path: "/tambores/visualizar/:id", Component: ViewBarrel },
  // companies
  { path: "/empresas/cadastrar", Component: CreateCompany },
  { path: "/empresas/listar", Component: ListCompany },
  { path: "/empresas/atualizar/:id", Component: EditCompany },
  { path: "/empresas/visualizar/:id", Component: ViewCompany },
  { path: "/contratos/listar", Component: ListContract },
  { path: "/contratos/cadastrar/:id", Component: CreateContract },
  { path: "/contratos/visualizar/:id", Component: ViewContract },
  { path: "/contratos/atualizar/:id", Component: EditContract },
  // drivers
  { path: "/motoristas/cadastrar", Component: CreateDriver },
  { path: "/motoristas/listar", Component: ListDriver },
  { path: "/motoristas/atualizar/:id", Component: EditDriver },
  { path: "/motoristas/visualizar/:id", Component: ViewDriver },

  { path: "/coletas/cadastrar/:id", Component: CreateCollect },
  { path: "/coletas/listar", Component: ListCollect },
  { path: "/coletas/atualizar/:id", Component: EditCollect },
  { path: "/coletas/visualizar/:id", Component: ViewCollect },

  { path: "/cobrancas/cadastrar", Component: CreateCharge },
  { path: "/cobrancas/listar", Component: ListCharge },
  { path: "/cobrancas/extrato", Component: ExtractCharge },

  { path: "/dashboard/default", Component: Default },
];
